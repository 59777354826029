import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    getEmail,
    getIfCurrentUserIsPrimaryUser,
    getinvesteeOnboardingIdanizationInfo,
    getInvesteeOrganizationInfo,
} from '../../../../../../App/AppReducer';
import { useState } from 'react';
import { useEffect } from 'react';
import { mixPanelTrack, showNotification, toWords } from '../../../../../../../util/utility';
import callApi from '../../../../../../../util/apiCaller';
import closeBtn from '../../../../../../../assets/close-btn.svg';
import {
    ADD_ON_LIMIT_STATUS,
    API_STATUS,
    CRORE,
    PRIVACY_POLICY_FOUNDERLINK,
    TERMS_CONDITIONS_FOUNDERLINK,
    VALID_ADD_ON_VALUES,
} from '../../../../../../../enums/appEnums';
import PageLoader from '../../../../../../../components/loader/PageLoader';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { getAddOnLimitStatus, getIfAddOnLimitModalOpen } from '../../../../../InvesteeReducers';
import { triggerAddOnLimitModal } from '../../../../../InvesteeActions';
import { isNil } from 'lodash';
import styles from './LimitAddOnModal.module.scss';
import { Mixpanel } from '../../../../../../../util/mixpanel';
import { fetchUpcomingPaymentDetails } from '../../../../../utility/utility';
import { getFundingCalculatorValues } from '../../../../../common/apiHelpers';
import { callAptToFetchCapitalAvailableData } from '../../../../../../../common/ApiContainer';

const OpenReq = styled.div`
    width: 101px;
    margin-left: 89px;
    text-align: center;
    padding: 5px 0;
    font-weight: 500;
    text-transform: uppercase;
    color: #ca9d17;
    font-size: 10px;
    border-radius: 4px;
    background-color: #fff7e9;
`;

function LimitAddOnModal({ show, handleClose, onRequestLimitAddOnSuccess, allowedToRequest }) {
    const { Terms } = styles;
    const [isOptions, setOptions] = useState(true);
    const [addonReq, setAddonReq] = useState({
        capital: '',
        timeline: '',
    });
    const [isLoading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [annualRevenue, setAnnualRevenue] = useState('');

    const limitAddOnStatus = useSelector(getAddOnLimitStatus);
    const investeeOnboardingId = useSelector(getInvesteeOrganizationInfo)?.investee_onboarded_id;
    const openModal = useSelector(getIfAddOnLimitModalOpen);

    const emailID = useSelector(getEmail);
    const isPrimaryUser = useSelector(getIfCurrentUserIsPrimaryUser);
    const companyInfo = useSelector(getInvesteeOrganizationInfo);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const checkAllowedToRequest = () => {
        return !isNil(allowedToRequest)
            ? allowedToRequest
            : [ADD_ON_LIMIT_STATUS.ALLOWED_TO_REQUEST, ADD_ON_LIMIT_STATUS.EXPIRED].includes(
                  limitAddOnStatus,
              );
    };

    //Validation in case the user clicks on the submit button without filling the required fields
    const handleValidation = () => {
        let isValid = true;
        let message = '';
        if (!investeeOnboardingId) {
            isValid = false;
            message = 'Some Error Occurred';
        }
        if (!addonReq.capital) {
            isValid = false;
            message = 'Capital field cannot be empty';
        }
        if (addonReq.timeline === '') {
            isValid = false;
            message = 'Timeline field cannot be empty';
        }
        return { isValid, message };
    };
    //Api call for addon Limit
    const handleSubmit = async () => {
        let paymentDetails = null;
        let customerType = null;

        try {
            const res = await fetchUpcomingPaymentDetails(investeeOnboardingId);
            if (res) {
                paymentDetails = res?.totalOverdueAmount > 0;
            }
        } catch (error) {
            console.error('Error fetching payment details:', error);
        }

        try {
            const capitalAmount = await callAptToFetchCapitalAvailableData(investeeOnboardingId);
            if (capitalAmount) {
                customerType =
                    capitalAmount?.data?.data?.drawnAmount > 0 ? 'Customer' : 'Onboarding';
            }
        } catch (error) {
            console.error('Error fetching capital available data:', error);
        }

        try {
            const Rev = await getFundingCalculatorValues({
                investeeOnboardingId,
                onSuccess: (res) => {
                    const toNumber = Number(res.data.arr_funding_cal.toFixed(7)) * CRORE; //converting to INR from CR
                    const arrFunding = toNumber.toLocaleString('en-In');
                    if (toNumber > 0) {
                        setAnnualRevenue(arrFunding);
                    } else {
                        setAnnualRevenue('');
                    }
                },
            });
        } catch (error) {
            console.error('Error fetching funding calculator values:', error);
        }

        if (checkAllowedToRequest() && isOptions) {
            mixPanelTrack({
                id: companyInfo?._id,
                trackUserObj: {
                    Source: 'Add On',
                    'Company Name': companyInfo?.name,
                    Email: emailID,
                    'Job Role': companyInfo?.authorized_designation,
                    Revenue: annualRevenue,
                    'Capital Expectation': addonReq?.capital,
                    Timeline: addonReq?.timeline,
                    isActive: companyInfo?.status,
                    isDPD: paymentDetails,
                    Customer: customerType,
                    isPrimaryUser: isPrimaryUser,
                },
                trackCategoryName: 'Application Started',
            });
        }

        if (checkAllowedToRequest() && isOptions) {
            setLoading(true);
            let validation = handleValidation();
            if (validation.isValid) {
                if (checkAllowedToRequest())
                    callApi('investee_onboarding/create-limit-add-on-request', 'post', {
                        investee_onboarded_id: investeeOnboardingId ? investeeOnboardingId : null,
                        add_on_amount: Number(addonReq.capital.replace(/\D/g, '')),
                        add_on_time_period: addonReq.timeline
                            ? addonReq.timeline.toLowerCase()
                            : addonReq.timeline,
                    }).then((res) => {
                        if (res.status === API_STATUS.SUCCESS) {
                            setLoading(false);
                            setOptions(false);
                            if (onRequestLimitAddOnSuccess) onRequestLimitAddOnSuccess();
                        } else {
                            setLoading(false);
                            showNotification('Error', res.error);
                        }
                    });
            } else {
                setLoading(false);
                showNotification('error', validation.message);
            }
        } else {
            handleClose();
            navigate('/company/add-on-limit');
        }
    };
    //For enabling and disabling the Proceed button
    const toDisable = (obj) => {
        const newAddonReq = obj ?? addonReq;
        return !(Number(newAddonReq.capital.replace(/\D/g, '')) > 0 && newAddonReq.timeline !== '');
    };

    const handleChange = (keyName, keyValue) => {
        let obj = { ...addonReq };
        if (keyName === 'capital') {
            const toNumber = Number(keyValue.replace(/\D/g, ''));
            const toLocale = toNumber.toLocaleString('en-In');
            obj[keyName] = toLocale;
            setAddonReq(obj);
        }
        if (keyName === 'timeline') {
            obj[keyName] = keyValue;
            setAddonReq(obj);
        }
        let isDisabled = toDisable(obj);
        if (isDisabled !== disable) {
            setDisable(isDisabled);
        }
    };

    const onClose = () => {
        if (openModal) dispatch(triggerAddOnLimitModal(false));
        if (handleClose) handleClose();
    };

    return (
        <>
            {isLoading ? (
                <PageLoader />
            ) : (
                <Modal show={show || openModal} onHide={onClose} className="LimitAddOnModal">
                    <Modal.Header>
                        {!checkAllowedToRequest() && <OpenReq>1 Request Open</OpenReq>}
                        {/* <div className='clr37 fs16 FFGB'>Request Add On Limit</div> */}
                        <Modal.Title style={{ textAlign: 'center' }}>
                            {checkAllowedToRequest() && isOptions && (
                                <div className="clr37 fs16 FFGB">Request Add On Limit</div>
                            )}
                        </Modal.Title>
                        <button onClick={() => onClose()} className="border-0 bgt">
                            <img src={closeBtn} alt="close-button" height="14px" width="14px" />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }} className="pt-0">
                        {/* Design for enter amount and tentative timeline. */}
                        {checkAllowedToRequest() && isOptions ? (
                            <div>
                                <div className="clr37 fs12 ff text-left">
                                    Input the amount of additional capital you are looking to raise
                                    using Recur Club and when.
                                </div>
                                <div className="modal_input text-left">
                                    <label>
                                        {addonReq.capital &&
                                            (toWords.convert(
                                                Number(addonReq.capital.replace(/\D/g, '')),
                                            ) === 'One Rupees Only'
                                                ? 'One Rupee Only'
                                                : toWords.convert(
                                                      Number(addonReq.capital.replace(/\D/g, '')),
                                                  ))}
                                    </label>
                                    <br />
                                    <input
                                        value={addonReq.capital}
                                        onChange={(e) => handleChange('capital', e.target.value)}
                                        required
                                        placeholder="Add On Capital Amount"
                                    />
                                </div>
                                {/* {addonReq.timeline ? (
                            <div className="AmountToWord mt-3 mb-1">Tentative Timeline</div>
                        ) : (
                            <br />
                        )} */}
                                <div className="modal_select">
                                    <label className="text-left">
                                        {addonReq.timeline ? 'Tentative Timeline' : ''}
                                    </label>
                                    <select
                                        defaultValue={addonReq.timeline}
                                        onChange={(e) => handleChange('timeline', e.target.value)}
                                        required
                                        placeholder="Tentative Timeline"
                                    >
                                        <option disabled hidden value="">
                                            Tentative Timeline
                                        </option>
                                        {Object.values(VALID_ADD_ON_VALUES).map((value, i) => (
                                            <option key={i} value={value}>
                                                {value === 'Immediately'
                                                    ? value
                                                    : `Within ${value}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Player
                                    src="https://assets7.lottiefiles.com/packages/lf20_74de4rko.json"
                                    background="transparent"
                                    loop
                                    speed="0.5"
                                    style={{
                                        width: '120px',
                                        height: '120px',
                                        margin: '-34px auto 10px auto',
                                    }}
                                    autoplay
                                ></Player>
                                <Player
                                    src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/Confetti.json"
                                    background="transparent"
                                    speed="0.5"
                                    style={{
                                        width: '84%',
                                        position: 'absolute',
                                        top: '0',
                                    }}
                                    loop
                                    autoplay
                                ></Player>
                                <div className="AddOnReq">Add-On Requested</div>
                                <br></br>
                                <div className="fs14 fw5">
                                    You’ll have to submit updated data,<br></br> for us to evaluate
                                    your request.
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="p-4 border-0">
                        {checkAllowedToRequest() && isOptions ? (
                            <button
                                disabled={disable}
                                data-dismiss="modal"
                                className="border-0 text-white fs12 blue-bg w-100 br6 fw3 m-0 limit-add-on-btn"
                                style={{ padding: '10px 0' }}
                                onClick={() => handleSubmit()}
                            >
                                Submit Data Now
                            </button>
                        ) : (
                            <button
                                data-dismiss="modal"
                                className="border-0 text-white fs12 blue-bg w-100 br6 fw3 m-0"
                                style={{ padding: '10px 0', zIndex: '1' }}
                                onClick={() => handleSubmit()}
                            >
                                Submit Data Now
                            </button>
                        )}
                        {checkAllowedToRequest() && (
                            <div className={`${Terms} mt-3`}>
                                By clicking on "Submit Data Now” you agree to our{' '}
                                <a
                                    className="purple-hover-text"
                                    href={TERMS_CONDITIONS_FOUNDERLINK}
                                    target="_blank"
                                    onClick={() => {
                                        window.localStorage.setItem(
                                            'is_terms_of_use_clicked',
                                            'yes',
                                        );
                                    }}
                                    rel="noreferrer"
                                >
                                    Terms of Service
                                </a>{' '}
                                and
                                <a
                                    className="purple-hover-text"
                                    href={PRIVACY_POLICY_FOUNDERLINK}
                                    target="_blank"
                                    onClick={() => {
                                        window.localStorage.setItem(
                                            'is_privacy_policy_clicked',
                                            'yes',
                                        );
                                    }}
                                    rel="noreferrer"
                                >
                                    {' '}
                                    Privacy Policy
                                </a>
                                .
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default LimitAddOnModal;
