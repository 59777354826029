import { CSSProperties, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getAutoInvestCount } from '../../modules/Investor/InvestorReducers';
import {
    getApprovedState,
    getInvesteeOrganizationInfo,
    getInvesteeSidebarConfigTab,
    getInvesteeOrganizationIsInsightsEnabled,
    getUserId,
    getUserPermissions,
    getWidgetData,
} from '../../modules/App/AppReducer';
import {
    fetchDataVaultPendingStatus,
    fetchAllowedInviteRolesRedux,
    fetchInvesteeWidgetData,
} from '../../modules/App/AppActions';
import clsx from 'clsx';
import WithdrawFunds from './components/WithdrawFunds';
import { USER_TYPE_INVESTEE } from '../../enums/appEnums';
import { basePathInvestee, getS3FLIconLink } from '../../util/utility';
import storage from '../../util/storageService';
import { MyComponentProps, Sidebar } from '../../modules/Investee/common/models/SidebarInvestee';
import userNameStyles from './styles/UsernameBtn.module.scss';
import Widget from '../../modules/Investee/components/Widget/Widget';
import { ROUTES_MAP, SIDEBAR_LABELS } from './consts/consts';
import LimitAddOnModal from '../../modules/Investee/pages/DashboardPage/components/SanctionedLimitPage/components/LimitAddOnModal';
import { DEAL_CHEST_WEBSITE } from '../../constants/consts';

const SidebarInvestee: React.FC<MyComponentProps> = (props: any) => {
    const dispatch = useDispatch();
    const LIMIT_OVERVIEW = 'LIMIT OVERVIEW';

    const { TermsOfService, ItemStyle, Dot } = userNameStyles;
    const navigate = useNavigate();

    const location = useLocation();
    const autoinvestCount = useSelector(getAutoInvestCount);
    const investeeOrgId = useSelector(getInvesteeOrganizationInfo)?._id;
    const isUserApproved = useSelector(getApprovedState);
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const isLimitAddOnRequested = useSelector(
        getInvesteeOrganizationInfo,
    )?.is_limit_add_on_requested;
    const userPermissions = useSelector(getUserPermissions);
    const insightsStatus = useSelector(getInvesteeOrganizationIsInsightsEnabled);
    const userId = useSelector(getUserId);

    const [listOfTabs, setListOfTabs] = useState<Sidebar[]>([]);
    const widget = useSelector(getWidgetData);

    const tabsNotRequiredInNavBar = [LIMIT_OVERVIEW];
    useEffect(() => {
        if (investeeOrgId && isUserApproved) dispatch(fetchDataVaultPendingStatus(investeeOrgId));
        if (userPermissions) dispatch(fetchAllowedInviteRolesRedux(userPermissions));
    }, [investeeOrgId]);

    useEffect(() => {
        if (sidebarConfig) {
            const newData: Sidebar[] = sidebarConfig.filter((item: Sidebar, index: number) => {
                if (item.display) {
                    //fetch url based on label except for labels which are not required to be shown in navbar
                    if (
                        ROUTES_MAP[item.label] &&
                        !tabsNotRequiredInNavBar.includes(item?.label?.toUpperCase())
                    )
                        item.url = ROUTES_MAP[item.label];
                    return item;
                }
            });
            newData.forEach((data, index) => {
                if (
                    data.label.toUpperCase() === SIDEBAR_LABELS.DATA_VAULT.toUpperCase() ||
                    data.label.toUpperCase() === SIDEBAR_LABELS.REPAYMENTS.toUpperCase()
                ) {
                    if (data.display)
                        newData.splice(index + 1, 0, { url: '#', name: '', line: true, label: '' });
                }
            });
            setListOfTabs(newData);
        }
    }, [sidebarConfig]);

    const goToInsights = () => {
        const cerberusToken = storage.get('cerberus_token');
        if (insightsStatus === 'Approved') {
            window.open(
                `${process.env.REACT_APP_INSIGHTS_URL}?investeeOrgId=${investeeOrgId}&cerberusToken=${cerberusToken}&userId=${userId}`,
            );
        }
    };

    const goToDealChest = () => {
        window.open(DEAL_CHEST_WEBSITE, '_blank');
    };

    const goToAddonJourney = () => {
        navigate(ROUTES_MAP[SIDEBAR_LABELS.ADD_ON_LIMIT]);
    };

    const renderNavLinks = (item: Sidebar, i?: number) => {
        const style: CSSProperties | null = item.block ? { cursor: 'default' } : null;
        if (props.selectedSegment === USER_TYPE_INVESTEE) {
            return (
                <NavLink
                    key={i}
                    data-name={item.label}
                    className={({ isActive }) => {
                        return clsx({
                            'sidebar-line': item.line,
                            'sidebar-modules':
                                !item.line &&
                                item.label !== 'Collections' &&
                                item.label !== 'Deal Chest' &&
                                !item.block,
                            'disable-modules': item.block,
                            'Insider-active': item.label === 'Deal Chest' && isActive,
                            'Insider-inactive': item.label === 'Deal Chest' && !isActive,
                            'Checkout-active': item.label === 'Collections' && isActive,
                            'Checkout-inactive': item.label === 'Collections' && !isActive,
                            'activeStyle-investee': isActive,
                            Insights: item.label === 'Recur Insights',
                        });
                    }}
                    style={style ?? undefined}
                    // isActive={() => this.checkActive(item)}
                    to={`${item.url}`}
                    onClick={(e) => {
                        if (item.label === SIDEBAR_LABELS.DATA_VAULT && isLimitAddOnRequested) {
                            e.preventDefault();
                            goToAddonJourney();
                        } else if (location.pathname === item.url || item.block) {
                            e.preventDefault();
                            return;
                        } else if (item.label === 'Recur Insights') {
                            goToInsights();
                        } else if (item.label === SIDEBAR_LABELS.DEAL_CHEST) {
                            goToDealChest();
                            e.preventDefault();
                            return;
                        }
                    }}
                >
                    {<span>{item.label}</span>}
                </NavLink>
            );
        }
        return (
            <NavLink
                hidden={
                    item.label === 'Cart' ||
                    item.label === 'Investbook' ||
                    item.label === 'Finances'
                }
                key={i}
                className={({ isActive }) => {
                    return clsx({
                        'sidebar-link investor-sidebar-link': true,
                        activeStyle: isActive,
                    });
                }}
                to={`${item.url}`}
                data-autoinvest={autoinvestCount ? autoinvestCount : 0}
                onClick={(e) => {
                    item.block && e.preventDefault();
                }}
            >
                {item.label}
            </NavLink>
        );
    };

    const renderSideBar = (item: Sidebar, i: number) => {
        if (item.label === 'WithdrawFunds') {
            return <WithdrawFunds key={`withdraw-funds-${i}`} />;
        }
        if (item.label === 'Header') {
            return null;
        }
        return renderNavLinks(item, i);
    };

    const onRequestLimitAddOnSuccess = () => {
        setTimeout(() => dispatch(fetchInvesteeWidgetData(userId)), 800); // to fetch widget data coz kyc coverage might have updated
    };
    return (
        <>
            <div className="sidebar">
                <div className="d-flex flex-column justify-content-between logout-ht position-relative">
                    <div className="d-flex flex-column">
                        {/* recur.svg is old recur logo */}
                        <img
                            className="sidebar-recur-logo"
                            onClick={
                                () => props.navigate(basePathInvestee(sidebarConfig, widget)) //@TODO remove
                            }
                            src={getS3FLIconLink('recur-new-logo-3', 'svg')}
                            alt=""
                        />
                        {listOfTabs?.map((item: Sidebar, idx: number) => {
                            return renderSideBar(item, idx);
                        })}
                        {widget?.display && (
                            <div className={!isUserApproved ? 'dflex-c' : 'dflex-c r-mt-8'}>
                                <Widget />
                            </div>
                        )}

                        {/*

                         - The Terms and policiesa are disabled as we are moving from Recur to
                        Founderlink
                        - These links will be disabled until then. Confirm once with
                        product team before Bringing them live
                         <div
                            className={TermsOfService}
                            style={{ position: 'absolute', bottom: '10px' }}
                        >
                            <div className={ItemStyle}>
                                <a
                                    href="https://www.recurclub.com/terms-of-use"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of service
                                </a>
                            </div>
                            <div className={Dot}></div>
                            <div className={ItemStyle}>
                                <a
                                    href="https://www.recurclub.com/privacy-policy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {' '}
                                    Privacy Policy
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* @ts-ignore */}
                <LimitAddOnModal onRequestLimitAddOnSuccess={onRequestLimitAddOnSuccess} />
            </div>
        </>
    );
};
export default SidebarInvestee;
