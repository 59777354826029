import { SHOW_NOTIFICATION_STATUS } from '../../../../../../../enums/appEnums';
import { showNotification } from '../../../../../../../util/utility';
import { ADD_ON_LIMIT_CONST, APPLICATION_TYPE, UPLOAD_STAGE } from '../../constants';

export const CATEGORIES = {
    MIS: 'mis',
    FINANCIAL: 'financial_statements',
    INVOICING: 'invoicing',
    CUSTOMER_CONTRACTS: 'customer_contracts',
    BANK_STATEMENTS: 'bills_invoices_bank_transactions',
    ADDITIONAL_DATA: 'additional_data',
    DEBT_SCHEDULE: 'debtSchedule',
    OD: 'OD',
    FD: 'FD',
    AUDITED_FINANCIALS: 'audited_financials',
    PROVISIONAL_FINANCIALS: 'provisional_financials',
    TDS: 'TDS',
    CP: 'CP',
    CUSTOMER_SUPPORT: 'customer_support',
    TOTAL_REPAYMENTS: 'total_repayments',
    CUSTOMER_SUPPORT_OTHER: 'customer_support_other',
    ADDITIONAL_REQUIRED_DOCUMENTS: 'additionalRequiredDocuments',
    GST: 'GST',
    ADDITIONAL_DOCS: 'additional_docs',
};
export const FILE_SIZE = 1_88_74_368;
export const FILE_SIZE_10_MB = 11000000;
export const FILE_SIZE_5_MB = 5500000;
//18MB

type HandleFilesValidationForDocsProps = {
    files?: File[];
    allowedExtensions?: string[];
    extensionFailureMessage?: string;
};

export const FILE_FORMATS = {
    EXCEL: [
        'csv',
        'xls',
        'xlsx',
        'xlsm',
        'xlsb',
        'xltx',
        'xltm',
        'xlt',
        'xlam',
        'xla',
        'xlw',
        'xlr',
    ],
    PDF: ['pdf'],
    EXCEL_PDF: [
        'pdf',
        'csv',
        'xlsx',
        'xlsm',
        'xlsb',
        'xltx',
        'xltm',
        'xls',
        'xlt',
        'xlam',
        'xla',
        'xlw',
        'xlr',
    ],
    IMG: ['jpg', 'png', 'jpeg'],
    IMG_PDF: ['pdf', 'jpg', 'png', 'jpeg'],
};
export const getFileExtension = (fileName: string) => {
    return fileName.split('.').pop();
};
export const getAcceptedExtensions = (category: string, keyName: string) => {
    let acceptedExtensions: string[] = [];
    switch (category) {
        case CATEGORIES.INVOICING:
        case CATEGORIES.DEBT_SCHEDULE:
        case CATEGORIES.CUSTOMER_CONTRACTS: {
            acceptedExtensions = FILE_FORMATS.EXCEL;
            break;
        }
        case CATEGORIES.TOTAL_REPAYMENTS:
            acceptedExtensions = FILE_FORMATS.IMG_PDF;
            break;
        case CATEGORIES.MIS:
        case CATEGORIES.OD:
        case CATEGORIES.FD:
        case CATEGORIES.PROVISIONAL_FINANCIALS:
        case CATEGORIES.FINANCIAL: {
            acceptedExtensions = FILE_FORMATS.EXCEL_PDF;
            break;
        }
        case CATEGORIES.CUSTOMER_SUPPORT:
        case CATEGORIES.TDS: {
            acceptedExtensions = FILE_FORMATS.PDF;
            break;
        }
        case CATEGORIES.BANK_STATEMENTS: {
            acceptedExtensions = FILE_FORMATS.PDF;
            break;
        }
        case CATEGORIES.CUSTOMER_SUPPORT_OTHER:
        default: {
            acceptedExtensions = [];
        }
    }

    return { acceptedExtensions };
};

export const handleFilesValidation = (files: File[], category: string, keyName: string) => {
    let filteredFiles: File[] = [];
    let { acceptedExtensions } = getAcceptedExtensions(category, keyName);
    if (files && files?.length) {
        filteredFiles = files.filter((file) => {
            if (file.size > FILE_SIZE) {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    `You cannot upload files greater than ${FILE_SIZE / (1024 * 1024)}MB`,
                );

                return false;
            } else if (
                file.size > FILE_SIZE_5_MB &&
                category === CATEGORIES.CUSTOMER_SUPPORT_OTHER
            ) {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    `You cannot upload files greater than ${FILE_SIZE_5_MB / (1024 * 1024)}MB`,
                );

                return false;
            }
            return true;
        });
    }

    return { acceptedExtensions, filteredFiles };
};

export const handleFilesValidationForDocs = ({
    files,
    allowedExtensions,
    extensionFailureMessage,
}: HandleFilesValidationForDocsProps) => {
    let message = '';
    let isValid = true;
    let extensionFailure = false;
    let sizeFailure = false;
    let acceptedStr = '';
    const PJ = allowedExtensions ?? FILE_FORMATS.EXCEL_PDF;
    let acceptedExtensions: string[];
    acceptedExtensions = PJ;
    files &&
        files?.forEach((file: File) => {
            const splitter = file.name.split('.');
            const extension = splitter[splitter.length - 1];
            if (acceptedExtensions && !acceptedExtensions?.includes(extension.toLowerCase())) {
                extensionFailure = true;
            }
            if (file.size > FILE_SIZE_10_MB) {
                sizeFailure = true;
            }
        });
    acceptedExtensions &&
        acceptedExtensions?.forEach((extn, index) => {
            if (index === acceptedExtensions.length - 1) {
                acceptedStr += '.' + extn;
            } else {
                acceptedStr += '.' + extn + ',';
            }
        });
    if (extensionFailure)
        message =
            extensionFailureMessage ??
            'File format not supported (only accepts .pdf/.csv/.xls/.xlsx)';
    else if (sizeFailure) message = 'You cannot upload files greater than 10MB';
    return {
        isValid,
        message,
        sizeFailure,
        extensionFailure,
        acceptedExtensions,
        acceptedStr,
    };
};

export const getInputAcceptValue = (category: string, keyName: string) => {
    let acceptedStr = '';
    const { acceptedExtensions } = handleFilesValidation([], category, keyName);
    acceptedExtensions &&
        acceptedExtensions.forEach((extn: string, index: number) => {
            if (index === acceptedExtensions.length - 1) {
                acceptedStr += '.' + extn;
            } else {
                acceptedStr += '.' + extn + ',';
            }
        });
    return acceptedStr;
};

const getFileNameAndExtension = (fileNameWithExtension: string) => {
    const lastDotIndex = fileNameWithExtension?.lastIndexOf?.('.');

    // Return -1 as there is no extension
    if (lastDotIndex === -1) {
        return {
            fileNameWithoutExtion: fileNameWithExtension,
            extension: '',
        };
    }

    const fileNameWithoutExtion = fileNameWithExtension?.substring?.(0, lastDotIndex);
    const extension = fileNameWithExtension?.substring?.(lastDotIndex + 1);

    return {
        fileNameWithoutExtion,
        extension,
    };
};

export const modifyFileNameBeforeUpload = (
    fileName: string,
    category: string,
    isTally: boolean,
) => {
    let file_name = fileName;
    if (category === CATEGORIES.MIS || category === CATEGORIES.FINANCIAL) {
        const { fileNameWithoutExtion, extension } = getFileNameAndExtension(file_name);
        file_name = `${fileNameWithoutExtion}_mis${isTally ? '_tally' : ''}.${extension}`;
    } else if (category === CATEGORIES.INVOICING) {
        const { fileNameWithoutExtion, extension } = getFileNameAndExtension(file_name);
        file_name = `${fileNameWithoutExtion}_invoices${isTally ? '_tally' : ''}.${extension}`;
    } else if (category === CATEGORIES.DEBT_SCHEDULE) {
        const { fileNameWithoutExtion, extension } = getFileNameAndExtension(file_name);
        file_name = `${fileNameWithoutExtion}_debt_schedule.${extension}`;
    } else if (category === CATEGORIES.OD) {
        file_name = `OD_${file_name}`;
        file_name = file_name.split(' ').join('_');
    } else if (category === CATEGORIES.FD) {
        file_name = `FD_${file_name}`;
        file_name = file_name.split(' ').join('_');
    } else {
        file_name = file_name.split(' ').join('_');
    }
    return file_name;
};
export const getIdentifierType = (path: string) => {
    if (path === UPLOAD_STAGE.ONBOARDING) {
        return APPLICATION_TYPE.ONBOARDING;
    } else if (path?.includes(ADD_ON_LIMIT_CONST)) {
        return APPLICATION_TYPE.ADD_ON_LIMIT;
    }
    return null;
};
