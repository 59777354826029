export const SIDEBAR_LABELS = {
    ONBOARDING: 'Onboarding',
    ADD_ON_LIMIT: 'AddOnLimit',
    LIMIT_DOCUMENTS: 'LimitDocuments',
    RECUR_PRODUCTS: 'Recur Products',
    DASHBOARD: 'Dashboard',
    DRAW_CAPITAL: 'Draw Capital',
    REPAYMENTS: 'Repayments',
    DATA_VAULT: 'Data Vault',
    REPORTS: 'Reports',
    DEAL_CHEST: 'Deal Chest',
    RECUR_INSIGHTS: 'Recur Insights',
    COLLECTIONS: 'Collections',
    LIMIT_OVERVIEW: 'Limit Overview',
};

export const ROUTES_MAP = {
    [SIDEBAR_LABELS.ONBOARDING]: '/company/onboarding',
    [SIDEBAR_LABELS.ADD_ON_LIMIT]: '/company/add-on-limit',
    [SIDEBAR_LABELS.LIMIT_DOCUMENTS]: '/company/get-started',
    [SIDEBAR_LABELS.RECUR_PRODUCTS]: '/company/recur-products',
    [SIDEBAR_LABELS.DASHBOARD]: '/company/dashboard',
    [SIDEBAR_LABELS.DRAW_CAPITAL]: '/company/trade',
    [SIDEBAR_LABELS.REPAYMENTS]: '/company/finances/transactions',
    [SIDEBAR_LABELS.REPORTS]: '/company/finances/reports',
    [SIDEBAR_LABELS.DATA_VAULT]: '/company/data-vault',
    [SIDEBAR_LABELS.DEAL_CHEST]: '/company/insiders',
    [SIDEBAR_LABELS.RECUR_INSIGHTS]: '/company/pro',
    [SIDEBAR_LABELS.COLLECTIONS]: '/company/collections',
    [SIDEBAR_LABELS.LIMIT_OVERVIEW]: '/company/dashboard/sanctioned-limit',
};
