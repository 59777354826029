import { memo, Suspense, useEffect, useState } from 'react';
import PageLoader from './components/loader/PageLoader';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    getIfCurrentUserIsPrimaryUser,
    getInvesteeOrganizationInfoCheckOut,
    getInvesteeSidebarConfigTab,
    getUserId,
    getUserInvesteeOrganizationId,
    getWidgetData,
} from './modules/App/AppReducer';
import {
    INVESTEE_BASE_PATH,
    INVESTEE_ROUTES,
    INVESTOR_BASE_PATH,
    INVESTOR_ROUTES,
} from './RoutesConst';
import {
    AutoInvestCreatePageLazy,
    AutoInvestPageLazy,
    CompanyDetailViewLazy,
    DataVaultPageLazy,
    GetStartedPageLazy,
    InsidersPageLazy,
    InvesteeDashboardPageLazy,
    InvesteeForgotPasswordPageLazy,
    InvesteeOnboardingPageLazy,
    InvesteeAddOnLimitLazy,
    InvesteeTradePageLazy,
    InvestorNewDealsPageLazy,
    InvestorForgotPasswordPageLazy,
    InvestorHelpCenterPageLazy,
    InvestorLoginPageLazy,
    InvestorMarketPageLazy,
    InvestorPortfolioPageLazy,
    InvestorProfilePageLazy,
    InvestorRequestSubmittedPageLazy,
    InvestorVerifyEmailPageLazy,
    InvestorResetPasswordPageLazy,
    InvestorSignUpLazy,
    KarzaGstLazy,
    OtpScreenLazy,
    PayOutTransactionsLazy,
    RecurCheckoutComponentLazy,
    RecurCheckoutPageLazy,
    ResetPasswordPageLazy,
    RecurProPageLazy,
    SignupGetStartedLazy,
    SignUpPageLazy,
    ReportsPageLazy,
    TermsOfTradeLazy,
    TradebookFiltersLazy,
    TransactionsOverviewLazy,
    VerifyEmailPageLazy,
    InvestorTransactionsDrawdownsLazy,
    InvestorTransactionsPaymentsLazy,
    InvestorStatementsLazy,
    InvestorTransactionsOverviewLazy,
    InvestorReportsLazy,
    InvestorInvoicesLazy,
    InvestorOtpLazy,
    InvestorDashboardLazy,
    InvestorDocVerificationPageLazy,
    CibilConfirmationScreenLazy,
    InvestorCounterSignPageLazy,
    RecurProductsPageLazy,
    GlobalNeedInfoLazy,
    CustomerSupportPageLazy,
    CustomerRequestsLazy,
    FundingCalculatorLazy,
} from './Lazy';
import InvesteePrivateRoutes from './modules/Investee/components/InvesteePrivateRoutes';
import InvestorPrivateRoute from './modules/Investor/components/InvestorPrivateRoute';
import InvestorBaseComponent from './modules/Investor/components/InvestorBaseComponent';
import InvestorIndexRoute from './modules/Investor/components/InvestorIndexRoute';
import NotFoundComponent from './components/NotFoundComponent';
import BaseInvesteeComponent from './modules/Investee/components/WithdrawAccountTooltip/BaseInvesteeComponent';
import { basePathInvestee } from './util/utility';
import { ROUTES_MAP, SIDEBAR_LABELS } from './components/Sidebar/consts/consts';
import { Mixpanel } from './util/mixpanel';
import { fetchUpcomingPaymentDetails } from './modules/Investee/utility/utility';
import { callAptToFetchCapitalAvailableData } from './common/ApiContainer';
import storage from './util/storageService';

/**
 *
 * @param {history} param
 * @returns
 * @pending
 * 1. private routes hook
 * 2. base path investee handle
 * 3. login page handle remove redirect
 * 4. check documentation of router for more compatibablity issues
 * 5. handle mismatch of investor vs investee app (will see if really want to this)
 */
function RootRoutes({ history }) {
    const [pathToNavigate, setPathToNavigate] = useState(ROUTES_MAP?.[SIDEBAR_LABELS.DASHBOARD]);
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const widget = useSelector(getWidgetData);
    const recurCheckoutAllowed = useSelector(getInvesteeOrganizationInfoCheckOut);
    const location = useLocation();
    const investeeOrganizationId = storage.get('org_id');
    const isPrimaryUser = useSelector(getIfCurrentUserIsPrimaryUser);
    const userId = useSelector(getUserId);
    const investeePaths = Object.values(INVESTEE_ROUTES.PRIVATE).map((item) => (item = '/' + item));
    const spiltedPath = location.pathname.split('/company')[1];
    let upCommingPaymentDetails = useSelector((state) => state.app.upCommingPaymentDetails);
    let capitalAmount = useSelector((state) => state.app.capitalAmount);

    useEffect(() => {
        const pathToNavigate = basePathInvestee(sidebarConfig, widget);
        setPathToNavigate(pathToNavigate);
    }, [sidebarConfig, widget]);

    useEffect(() => {
        const fetchDataAndTrack = async () => {
            try {
                if (investeePaths.includes(spiltedPath)) {
                    const fetchDetails = await fetchUpcomingPaymentDetails(investeeOrganizationId);
                    const fetchedAmounts = await callAptToFetchCapitalAvailableData(
                        investeeOrganizationId,
                    );

                    const fetchedUpComingPaymentDetail = upCommingPaymentDetails
                        ? upCommingPaymentDetails
                        : fetchDetails;
                    const fetchCapitalAmount = capitalAmount ? capitalAmount : fetchedAmounts;

                    Mixpanel.track('Page View', {
                        $websiteUrl: window.location.href,
                        $$distinct_id: investeeOrganizationId,
                        $isPrimaryUser: isPrimaryUser,
                        $is_Company_Active:
                            fetchedUpComingPaymentDetail?.totalOverdueAmount > 0 ||
                            fetchedUpComingPaymentDetail?.repayments.length > 0,
                        $userId: userId,
                        $orgId: investeeOrganizationId,
                        $isDPDCustomer: fetchedUpComingPaymentDetail?.totalOverdueAmount > 0,
                        $company_Status:
                            fetchCapitalAmount?.data?.data?.drawnAmount > 0
                                ? 'Customer'
                                : 'Onboarding',
                    });
                } else {
                    Mixpanel.track('Page View', {
                        websiteUrl: window.location.href,
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataAndTrack();
    }, [location]);

    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                {/**-------------- INVESTEE ROUTES STARTS ---------------- */}
                <Route
                    path={INVESTEE_BASE_PATH}
                    element={
                        <BaseInvesteeComponent
                            pathToNavigate={pathToNavigate}
                            sidebarConfig={sidebarConfig}
                            widget={widget}
                        />
                    }
                />

                <Route path="/:id/recur-checkout" element={<RecurCheckoutComponentLazy />} />
                <Route path="/:id/gst-invoices-fetch" element={<KarzaGstLazy />} />
                <Route path={INVESTEE_ROUTES.PUBLIC.SIGNUP} element={<SignUpPageLazy />} />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.SIGNUP_GET_STARTED}
                    element={<SignupGetStartedLazy />}
                />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.CIBIL_VERIFICATION}
                    element={<CibilConfirmationScreenLazy />}
                />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.FUNDING_CALCULATOR}
                    element={<FundingCalculatorLazy />}
                />
                <Route path={INVESTEE_ROUTES.PUBLIC.OTP_SCREEN} element={<OtpScreenLazy />} />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.FORGOT_PASSWORD}
                    element={<InvesteeForgotPasswordPageLazy />}
                />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.RESET_PASSWORD}
                    element={<ResetPasswordPageLazy />}
                />
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.VERIFY_EMAIL}
                    element={<VerifyEmailPageLazy />}
                />
                {/* @TODO:- Use this route when OTP is fixed  */}
                {/* <Route path={INVESTEE_ROUTES.PUBLIC.VERIFY} element={<VerifyContactsPage />} /> */}
                <Route
                    path={INVESTEE_ROUTES.PUBLIC.TRADEBOOK_FILTERS}
                    element={<TradebookFiltersLazy />}
                />
                {/**This one handles the 404 pages of non logged in users */}
                <Route path="*" element={<NotFoundComponent />} />

                <Route
                    path={INVESTEE_ROUTES.PRIVATE.BASE_PATH}
                    element={<InvesteePrivateRoutes pathToNavigate={pathToNavigate} />}
                >
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.ONBOARDING}
                        element={<InvesteeOnboardingPageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.ADD_ON_LIMIT}
                        element={<InvesteeAddOnLimitLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.DASHBOARD}
                        element={<InvesteeDashboardPageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.CUSTOMER_SUPPORT}
                        element={<CustomerSupportPageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.TRADE}
                        element={<InvesteeTradePageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.GET_STARTED}
                        element={<GetStartedPageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.RECUR_PRODUCTS}
                        element={<RecurProductsPageLazy />}
                    />
                    <Route path={INVESTEE_ROUTES.PRIVATE.PRO} element={<RecurProPageLazy />} />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.REPAYMENTS}
                        element={<TransactionsOverviewLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.TRANSACTIONS_PAYOUTS}
                        element={<PayOutTransactionsLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.TRANSACTIONS_PAYMENTS}
                        element={<PayOutTransactionsLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.FINANCES_REPORTS}
                        element={<ReportsPageLazy />}
                    />
                    <Route path={INVESTEE_ROUTES.PRIVATE.INSIDERS} element={<InsidersPageLazy />} />
                    {/**place a check here for recur checkout */}
                    {recurCheckoutAllowed && (
                        <Route
                            path={INVESTEE_ROUTES.PRIVATE.COLLECTIONS}
                            element={<RecurCheckoutPageLazy />}
                        />
                    )}
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.TRADE_LIMITS}
                        element={<DataVaultPageLazy isTradeLimits={true} />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.DATA_VAULT}
                        element={<DataVaultPageLazy />}
                    />
                    <Route
                        path={INVESTEE_ROUTES.PRIVATE.GLOBALNEEDINFO}
                        element={<GlobalNeedInfoLazy />}
                    />
                    <Route path={INVESTEE_ROUTES.PRIVATE.TERMS} element={<TermsOfTradeLazy />} />
                    {/**This will render on /company and force user to go at dashboard */}
                    <Route index element={<Navigate to={pathToNavigate} />} />
                    {/**This will handled the 404 pages of investee base path */}
                    <Route path="*" element={<NotFoundComponent />} />
                </Route>

                {/**Base path changes based on application state such login vs nonlogin and investor vs investee*/}
                {/**-------------- INVESTEE ROUTES ENDS ---------------- */}
                <Route path={INVESTOR_BASE_PATH} element={<InvestorBaseComponent />}>
                    <Route index element={<InvestorIndexRoute />} />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.LOGIN}
                        element={<InvestorLoginPageLazy />}
                    />
                    <Route path={INVESTOR_ROUTES.PUBLIC.SIGNUP} element={<InvestorSignUpLazy />} />
                    <Route path={INVESTOR_ROUTES.PUBLIC.OTP_SCREEN} element={<InvestorOtpLazy />} />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.FORGOT_PASSWORD}
                        element={<InvestorForgotPasswordPageLazy />}
                    />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.RESET_PASSWORD}
                        element={<InvestorResetPasswordPageLazy />}
                    />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.VERIFY_EMAIL}
                        element={<InvestorVerifyEmailPageLazy />}
                    />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.REQUEST_SUBMITTED}
                        element={<InvestorRequestSubmittedPageLazy />}
                    />
                    <Route
                        path={INVESTOR_ROUTES.PUBLIC.COUNTER_SIGN}
                        element={<InvestorCounterSignPageLazy />}
                    />
                    {/**INVESTOR ----->>>> PRIVATE ROUTES STARTS */}
                    <Route index element={<Navigate to={INVESTOR_ROUTES.PRIVATE.DASHBOARD} />} />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.DASHBOARD}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorDashboardLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.DEALS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorNewDealsPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.DOCS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorDocVerificationPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.CREATE_STRATERGY}
                        element={
                            <InvestorPrivateRoute>
                                <AutoInvestCreatePageLazy mode={'create'} />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.VIEW_EDIT_STRATERGY}
                        element={
                            <InvestorPrivateRoute>
                                <AutoInvestCreatePageLazy mode={'view'} />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.STRATERGY}
                        element={
                            <InvestorPrivateRoute>
                                <AutoInvestPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.PROFILE}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorProfilePageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.HELP}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorHelpCenterPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.PORTFOLIO}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorPortfolioPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_TRANSACTIONS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorTransactionsOverviewLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_REPORTS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorReportsLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_PAYMENTS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorTransactionsPaymentsLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_DRAW_DOWN}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorTransactionsDrawdownsLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.CUSTOMER_REQUESTS}
                        element={
                            <InvestorPrivateRoute>
                                <CustomerRequestsLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_STATEMENTS}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorStatementsLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.FINANCES_INVOICES}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorInvoicesLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.TERMS}
                        element={
                            <InvestorPrivateRoute>
                                <TermsOfTradeLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.MARKET_ID}
                        element={
                            <InvestorPrivateRoute>
                                <CompanyDetailViewLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    <Route
                        path={INVESTOR_ROUTES.PRIVATE.MARKET}
                        element={
                            <InvestorPrivateRoute>
                                <InvestorMarketPageLazy />
                            </InvestorPrivateRoute>
                        }
                    />
                    {/**INVESTOR ----->>>> PRIVATE ROUTES STARTS */}
                    <Route path="*" element={<NotFoundComponent isInvestor />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default memo(RootRoutes);
