import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { API_STATUS } from '../../enums/appEnums';
import callApi from '../../util/apiCaller';
import { calculateTerm } from '../../util/calculateTermUtil';
import { generateRequestId, showNotification } from '../../util/utility';
import { investorInviteModal } from '../App/AppActions';
import { captureMessagesViaErrorLogger } from '../../util/errorLogger';
import {
    getDealManagerMapping,
    getInvestorDrawdowns,
    getInvestorOpsMapping,
    getUnderwritingMapping,
} from '../Investee/common/apiHelpers';

export const FETCHING_INVESTOR_TEAM_MEMBERS = 'FETCHING_INVESTOR_TEAM_MEMBERS';
export const FETCH_INVESTOR_TEAM_MEMBERS = 'FETCH_INVESTOR_TEAM_MEMBERS';

export const ADDING_NEW_INVESTOR_MEMBER = 'ADDING_NEW_INVESTOR_MEMBER';
export const ADD_NEW_INVESTOR_MEMBER = 'ADD_NEW_INVESTOR_MEMBER';
export const ADDING_NEW_INVESTOR_MEMBER_FAILED = 'ADDING_NEW_INVESTOR_MEMBER_FAILED';

export const FETCH_INVESTOR_CONTRACTS = 'FETCH_INVESTOR_CONTRACTS';
export const FETCHING_INVESTOR_CONTRACTS = 'FETCHING_INVESTOR_CONTRACTS';
export const RESET_INVESTOR_CONTRACTS = 'RESET_INVESTOR_CONTRACTS';

export const FETCHING_INVESTEE_CONTRACTS = 'FETCHING_INVESTEE_CONTRACTS';
export const RESET_INVESTEE_CONTRACTS = 'RESET_INVESTEE_CONTRACTS';
export const FETCH_INVESTEE_CONTRACTS = 'FETCH_INVESTEE_CONTRACTS';
export const FETCHING_INVESTEE_CONTRACTS_FAILED = 'FETCHING_INVESTEE_CONTRACTS_FAILED';

export const RESET_INVESTOR_TANSACTIONS = 'RESET_INVESTOR_TANSACTIONS';
export const FETCH_INVESTOR_TRANSACTIONS = 'FETCH_INVESTOR_TRANSACTIONS';
export const FETCHING_INVESTOR_TRANSACTIONS = 'FETCHING_INVESTOR_TRANSACTIONS';
export const FETCHING_INVESTOR_DRAWDOWNS = 'FETCHING_INVESTOR_DRAWDOWNS';
export const FETCH_INVESTOR_DRAWDOWNS = 'FETCH_INVESTOR_DRAWDOWNS';

export const FETCHING_INVESTOR_INVESTBOOK_CONTRACTS = 'FETCHING_INVESTOR_INVESTBOOK_CONTRACTS';
export const FETCHING_DRAWDOWN_ORDERS = 'FETCHING_DRAWDOWN_ORDERS';
export const FETCH_INVESTOR_INVESTBOOK_CONTRACTS = 'FETCH_INVESTOR_INVESTBOOK_CONTRACTS';
export const RESET_INVESTOR_INVESTBOOK_CONTRACTS = 'RESET_INVESTOR_INVESTBOOK_CONTRACTS';
export const FETCH_DRAWDOWN_ORDERS = 'FETCH_DRAWDOWN_ORDERS';

export const FETCHING_INVESTBOOK_COUNT_DATA = 'FETCHING_INVESTBOOK_COUNT_DATA';
export const FETCH_INVESTBOOK_COUNT_DATA = 'FETCH_INVESTBOOK_COUNT_DATA';

export const FETCHING_INVESTOR_CART = 'FETCHING_INVESTOR_CART';
export const FETCH_INVESTOR_CART = 'FETCH_INVESTOR_CART';

export const ADDING_TO_INVESTOR_CART = 'ADDING_TO_INVESTOR_CART';
export const ADD_TO_INVESTOR_CART = 'ADD_TO_INVESTOR_CART';

export const REMOVING_TO_INVESTOR_CART = 'REMOVING_TO_INVESTOR_CART';
export const REMOVE_TO_INVESTOR_CART = 'REMOVE_TO_INVESTOR_CART';

export const PLACING_TO_INVESTOR_CART = 'PLACING_TO_INVESTOR_CART';
export const PLACE_TO_INVESTOR_CART = 'PLACE_TO_INVESTOR_CART';

export const PLACING_ORDER_FOR_INVESTEE = 'PLACING_ORDER_FOR_INVESTEE';
export const PLACED_ORDER_OF_INVESTEE = 'PLACED_ORDER_OF_INVESTEE';

export const SET_MARKET_COMPANY_VIEW = 'SET_MARKET_COMPANY_VIEW';

export const SET_MARKET_CONTRACT_VIEW = 'SET_MARKET_CONTRACT_VIEW';

export const ADDING_CART_FOR_INVESTEE = 'ADDING_CART_FOR_INVESTEE';
export const ADDED_CART_OF_INVESTEE = 'ADDED_CART_OF_INVESTEE';

export const FETCH_REPAYMENT_SCHEDULE = 'FETCH_REPAYMENT_SCHEDULE';
export const FETCHING_REPAYMENT_SCHEDULE = 'FETCHING_REPAYMENT_SCHEDULE';

export const CLEAR_MARKET_CONTRACTS_ON_COMPANY_CHANGE = 'CLEAR_MARKET_CONTRACTS_ON_COMPANY_CHANGE';

export const FETCHING_INVESTOR_ACCOUNT_STATEMENT = 'FETCHING_INVESTOR_ACCOUNT_STATEMENT';
export const FETCH_INVESTOR_ACCOUNT_STATEMENT = 'FETCH_INVESTOR_ACCOUNT_STATEMENT';

export const FETCH_ALL_AUTOINVEST = 'FETCH_ALL_AUTOINVEST';

export const FETCHING_OVERVIEW_DATA = 'FETCHING_OVERVIEW_DATA';
export const FETCH_OVERVIEW_DATA = 'FETCH_OVERVIEW_DATA';
export const SET_REPAYMENT_STATUS_FILTER = 'SET_REPAYMENT_STATUS_FILTER';

export const FETCH_UNDERWRITER_DETAILS_FOR_DEALS = 'FETCH_UNDERWRITER_DETAILS_FOR_DEALS';
export const FETCH_DEAL_MANAGER_DETAILS_FOR_DEBT_DEALS =
    'FETCH_DEAL_MANAGER_DETAILS_FOR_DEBT_DEALS';
export const FETCH_INVESTOR_OPS_DETAILS = 'FETCH_INVESTOR_OPS_DETAILS';

const { SUCCESS, ERROR } = API_STATUS;

export function fetchUnderwriterDetailsForDeals(investorOrgId) {
    return async (dispatch) => {
        const underwriterDetailsforDeals = await getUnderwritingMapping({
            investorOrganizationId: investorOrgId,
        });
        dispatch({
            type: FETCH_UNDERWRITER_DETAILS_FOR_DEALS,
            underwriterDetailsforDeals,
        });
    };
}
export function fetchDealManagerDetailsForDebtDeals(investorOrgId) {
    return async (dispatch) => {
        let requestId = generateRequestId();
        const dealManagerDetailsForDebtDeals = await getDealManagerMapping({
            investorOrganizationId: investorOrgId,
            requestId: requestId,
        });
        if (dealManagerDetailsForDebtDeals) {
            dispatch({
                type: FETCH_DEAL_MANAGER_DETAILS_FOR_DEBT_DEALS,
                dealManagerDetailsForDebtDeals,
            });
        }
    };
}

export function fetchInvestorTeamMembers(investor_organization_id) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_TEAM_MEMBERS,
            is_fetching_members: true,
        });
        return callApi(
            `investorOrganization/${investor_organization_id}/profile/get-team`,
            'get',
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: FETCH_INVESTOR_TEAM_MEMBERS,
                    members: res.data.investors,
                    is_fetching_members: false,
                });
            } else {
                dispatch({
                    type: FETCHING_INVESTOR_TEAM_MEMBERS,
                    is_fetching_members: false,
                });
            }
        });
    };
}

export function addNewInvestorMember(investor_organization_id, teammate) {
    return (dispatch) => {
        dispatch({
            type: ADDING_NEW_INVESTOR_MEMBER,
            is_adding_investor_member: true,
        });
        return callApi(`investor/${investor_organization_id}/invite-teammate`, 'post', {
            teammate: teammate,
            investor_organization_id: investor_organization_id,
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                res.data.userExists
                    ? showNotification('Error', res.data.message)
                    : showNotification('Success', 'New Member Added!');
                dispatch(investorInviteModal(false));
                dispatch({
                    type: ADD_NEW_INVESTOR_MEMBER,
                    member: res.data.savedInvestor,
                    is_adding_investor_member: false,
                    is_adding_investor_member_failed: false,
                });
            } else {
                showNotification('Error', res.data.message);
                dispatch({
                    type: ADDING_NEW_INVESTOR_MEMBER_FAILED,
                    is_adding_investor_member: false,
                    is_adding_investor_member_failed: true,
                });
            }
        });
    };
}

export function clearContractsOnCompanyChange() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_MARKET_CONTRACTS_ON_COMPANY_CHANGE,
        });
    };
}

export function fetchInvestorContracts(investee_organization_id, filters, isFiltering = false) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_CONTRACTS,
            is_fetching_open_contracts: true,
        });
        return callApi(`investor/contract-list`, 'post', {
            filters,
            investee_organization_id: investee_organization_id,
        }).then((res) => {
            let trade_contracts = [];
            if (res.status === API_STATUS.SUCCESS) {
                if (res.data && res.data.contracts.investee) {
                    trade_contracts = res.data.contracts.investee.map((item) => {
                        let termObj = calculateTerm(
                            item,
                            item.contract_payment_delay
                                ? item.contract_payment_delay
                                : item.investee_organization_id.global_delay,
                        );
                        // let xnpv = calculateXNPV(item.investee_organization_id.irr,termObj.transactionsArr,item.recurring_payment_value);
                        item.payOut = item.contract_xnpv;
                        item.term = termObj.term;
                        item.term_numeric = termObj.term_numeric;
                        item.repayment_schedule = termObj.transactionsArr;
                        return item;
                    });
                }
                dispatch({
                    type: FETCH_INVESTOR_CONTRACTS,
                    open_contracts: trade_contracts,
                    open_contracts_total_count: res.data.contracts.count,
                    is_fetching_open_contracts: false,
                    pageNum: filters.pageNum,
                });
            } else {
                showNotification('error', 'Some error occurred');
                dispatch({
                    type: FETCHING_INVESTOR_CONTRACTS,
                    is_fetching_open_contracts: false,
                });
            }
        });
    };
}

export function fetchInvestorDrawdowns(input) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_DRAWDOWNS,
            isFetchingInvestorPayments: true,
        });
        const res = await callApi(
            'investororganization/finance/transaction-list/payouts',
            'post',
            input,
        );
        if (res.status === API_STATUS.SUCCESS) {
            dispatch({
                type: FETCH_INVESTOR_DRAWDOWNS,
                investorDrawdowns: res.data.drawdownTransactions,
                investorDrawdownsTotalCount: res.data.drawdownTransactionsCount,
                isFetchingInvestorDrawdowns: false,
            });
        } else {
            dispatch({
                type: FETCHING_INVESTOR_DRAWDOWNS,
                isFetchingInvestorDrawdowns: false,
            });
        }
    };
}

export function fetchInvestorTransactions(input, queryParamsObject = null) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_TRANSACTIONS,
            isFetchingInvestorPayments: true,
        });
        const queryParams = queryParamsObject
            ? `?${new URLSearchParams(queryParamsObject).toString()}`
            : '';

        return callApi(
            `/investororganization/finance/transaction-list/payments${queryParams}`, // Append the query parameters to the URL if they are provided
            'post',
            input,
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: FETCH_INVESTOR_TRANSACTIONS,
                    investorPayments: res.data.aggregateOrderTrns,
                    investorPaymentsTotalCount: res.data.aggregateOrderTrnsCount,
                    isFetchingInvestorPayments: false,
                });
            } else {
                dispatch({
                    type: FETCHING_INVESTOR_TRANSACTIONS,
                    isFetchingInvestorPayments: false,
                });
            }
        });
    };
}

export function fetchInvestorCartItems(investor_organization_id, input) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_CART,
            is_fetching_cart_contracts: true,
        });
        return callApi(
            `investor/investororganization/${investor_organization_id}/view-cart`,
            'post',
            input,
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                let cart_contracts = [];
                if (res.data && res.data.contracts) {
                    cart_contracts = res.data.contracts.map((item) => {
                        let termObj = calculateTerm(
                            item.contract_id,
                            item.contract_id.contract_payment_delay
                                ? item.contract_id.contract_payment_delay
                                : item.investee_organization_id.global_delay,
                        );
                        // let xnpv = calculateXNPV(item.investee_organization_id.irr,termObj.transactionsArr,item.contract_id.recurring_payment_value);
                        item.payOut = item.contract_xnpv;
                        item.term = termObj.term;
                        item.term_numeric = termObj.term_numeric;
                        return item;
                    });
                }

                dispatch({
                    type: FETCH_INVESTOR_CART,
                    cart_contracts: cart_contracts,
                    is_fetching_cart_contracts: false,
                    cart_contracts_total_count: res.data.count,
                });
            } else {
                showNotification('Error', res.data?.message);
                dispatch({
                    type: FETCHING_INVESTOR_CART,
                    is_fetching_cart_contracts: false,
                });
            }
        });
    };
}

export function addContractToCart(investorwishlist) {
    return (dispatch, getState) => {
        dispatch({
            type: ADDING_TO_INVESTOR_CART,
            is_adding_cart_contracts: true,
        });
        return callApi(`investor/add-to-cart`, 'post', {
            investorwishlist: investorwishlist,
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS && res.data) {
                dispatch({
                    type: ADD_TO_INVESTOR_CART,
                    investorwishlist: investorwishlist,
                    is_adding_cart_contracts: false,
                });
            } else {
                showNotification('Error', res.data.message);
                dispatch({
                    type: ADDING_TO_INVESTOR_CART,
                    is_adding_cart_contracts: false,
                });
            }
        });
    };
}

export function removeContractFromCart(
    investorwishlist_id,
    investorOrganizationId,
    calculateNetIRR,
) {
    return (dispatch) => {
        dispatch({
            type: REMOVING_TO_INVESTOR_CART,
            is_removing_cart_contracts: true,
        });
        return callApi(
            `investor/${investorOrganizationId}/investorwishlist/${investorwishlist_id}/remove-from-cart`,
            'get',
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: REMOVE_TO_INVESTOR_CART,
                    investorwishlist_id: investorwishlist_id,
                    is_removing_cart_contracts: false,
                    cart_contracts_total_count: res.data.count,
                });
                showNotification('Success', 'Removed from Cart');
            } else {
                showNotification('Error', res.data.message);
                dispatch({
                    type: REMOVING_TO_INVESTOR_CART,
                    is_removing_cart_contracts: false,
                });
            }
        });
    };
}

export function fetchRepaymentSchedule(
    contract_id_array,
    dispatchedOnHover = false,
    trade_id,
    is_for_listed = false,
    is_moratorium_on = 0,
) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_REPAYMENT_SCHEDULE,
            is_fetching_repayment_schedule: true,
        });
        if (contract_id_array && contract_id_array.length > 0) {
            return callApi(`contract_transactions/repayment-schedule`, 'post', {
                contract_ids: contract_id_array,
                ...(!isEmpty(trade_id) && { trade_id: trade_id }),
                ...(!isNil(is_for_listed) && { is_for_listed: is_for_listed }),
            }).then((res) => {
                if (res.status === API_STATUS.SUCCESS) {
                    dispatch({
                        type: FETCH_REPAYMENT_SCHEDULE,
                        repayment_schedule: res.data,
                        is_fetching_repayment_schedule: false,
                        dispatchedOnHover: dispatchedOnHover,
                    });
                } else {
                    const message = res.data?.message ? res.data?.message : 'Some error occurred';
                    showNotification('Error', message);
                    dispatch({
                        type: FETCHING_REPAYMENT_SCHEDULE,
                        is_fetching_repayment_schedule: false,
                    });
                }
            });
        } else {
            return {};
        }
    };
}

export function placeOrderForContracts(investor_organization_id, wishlist) {
    return (dispatch) => {
        dispatch({
            type: PLACING_TO_INVESTOR_CART,
            is_placing_order: true,
        });
        return callApi(
            `investor/investororganization/${investor_organization_id}/place-order`,
            'post',
            {},
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: PLACE_TO_INVESTOR_CART,
                    is_placing_order: false,
                });
            } else {
                showNotification('Error', res.data.message);
                dispatch({
                    type: PLACING_TO_INVESTOR_CART,
                    is_placing_order: false,
                });
            }
        });
    };
}

export function fetchFilteredInvestbookContract(input, filters, isFiltering = false) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_INVESTBOOK_CONTRACTS,
            is_fetching_investor_investbook_contracts: true,
            investeeContractPageNum: filters.pageNum,
        });
        return callApi(`investor/investment-list`, 'post', {
            filters: filters,
            investor_organization_id: input.investor_organization_id,
            // investor_organization_id:"609a2de3fefea32202a8b792"
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: RESET_INVESTOR_INVESTBOOK_CONTRACTS,
                    isFiltering: isFiltering,
                });
                dispatch({
                    type: FETCH_INVESTOR_INVESTBOOK_CONTRACTS,
                    invested_contracts: res.data.investmentData.traded_contracts,
                    investedContractsTotalCount: res.data.investmentData.count,
                    is_fetching_investor_investbook_contracts: false,
                });
            } else {
                dispatch({
                    type: FETCHING_INVESTOR_INVESTBOOK_CONTRACTS,
                    is_fetching_investor_investbook_contracts: false,
                });
            }
        });
    };
}

export function fetchInvestBookCountData(input) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTBOOK_COUNT_DATA,
            is_fetching_investbook_count_data: true,
        });
        return callApi(`/investor/investment-list/contract-status-counts`, 'post', {
            investor_organization_id: input.investor_organization_id,
            // investor_organization_id:"609a2de3fefea32202a8b792"
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS && res.data) {
                dispatch({
                    type: FETCH_INVESTBOOK_COUNT_DATA,
                    investbookCountData: res.data.investbook_counts,
                    is_fetching_investbook_count_data: false,
                });
            } else {
                dispatch({
                    type: FETCHING_INVESTBOOK_COUNT_DATA,
                    is_fetching_investbook_count_data: false,
                });
            }
        });
    };
}

export function fetchCompaniesForMarket(filters) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTEE_CONTRACTS,
            is_fetching_investee_contracts: true,
        });
        return callApi(`investor/investee-organization-list`, 'post', filters).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: RESET_INVESTEE_CONTRACTS,
                    isFiltering: filters.isFiltering,
                });
                dispatch({
                    type: FETCH_INVESTEE_CONTRACTS,
                    investee_contracts: res.data.investee_organization.investee,
                    investeeContractTotalCount: res.data.investee_organization.count,
                    investeeContractTotalInvestableArr:
                        res.data.investee_organization.total_investable_arr,
                    is_fetching_investee_contracts: false,
                    investeeContractPageNum: filters.pageNum,
                });
            } else {
                res.data && showNotification('error', res.data.message);
                dispatch({
                    type: FETCHING_INVESTEE_CONTRACTS_FAILED,
                    is_fetching_investee_contracts: false,
                });
            }
        });
    };
}

export function fetchInvestorAccountStatement(
    investorOrganizationId,
    body,
    isFirstRender,
    isReseting,
) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_ACCOUNT_STATEMENT,
            is_fetching_account_statement: true,
        });
        return callApi(
            `repayments/investor-organization/${investorOrganizationId}/account-statements`,
            'post',
            body,
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS && res.data) {
                dispatch({
                    type: FETCH_INVESTOR_ACCOUNT_STATEMENT,
                    is_fetching_account_statement: false,
                    investor_account_statement: res.data.banklist.data,
                    account_statement_total_records: res.data.banklist.total_records,
                    isReseting: isReseting,
                });
            } else {
                dispatch({
                    type: FETCHING_INVESTOR_ACCOUNT_STATEMENT,
                    is_fetching_account_statement: false,
                });
            }
        });
    };
}

export function placeOrderForInvestee(investor_organization_id, body) {
    return (dispatch) => {
        dispatch({
            type: PLACING_ORDER_FOR_INVESTEE,
            is_placing_order_for_investee: true,
        });
        return callApi(
            `/investor/investororganization/${investor_organization_id}/place-order`,
            'post',
            body,
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS && res.data) {
                dispatch({
                    type: PLACED_ORDER_OF_INVESTEE,
                    investee_placed_contracts: res.data.ordered_contracts,
                    cart_contracts_total_count: res.data.count,
                    is_placing_order_for_investee: false,
                    order: res.data.order,
                });
                dispatch({
                    type: SET_MARKET_COMPANY_VIEW,
                    marketCompanyView: 'order',
                });
            } else {
                showNotification('error', res.data.message);
                dispatch({
                    type: PLACING_ORDER_FOR_INVESTEE,
                    is_placing_order_for_investee: false,
                });
            }
        });
    };
}

export function addCartForInvestee(wishlist, investor_organization_id, body) {
    return (dispatch, getState) => {
        dispatch({
            type: ADDING_CART_FOR_INVESTEE,
            is_adding_cart_for_investee: true,
        });
        return callApi(`/investor/add-to-cart`, 'post', body).then((res) => {
            let cart_contracts_added = [];
            if (res.status === API_STATUS.SUCCESS && res.data) {
                let state = getState();
                let cart_contracts = state.investor.cart_contracts;
                let investorwishlist = res.data.wishlist;
                let total = investorwishlist.concat(cart_contracts);
                cart_contracts_added = res.data.wishlist.map((item) => {
                    let termObj = calculateTerm(
                        item,
                        item.contract_payment_delay
                            ? item.contract_payment_delay
                            : item.investee_organization_id.global_delay,
                    );
                    // let xnpv = calculateXNPV(item.investee_organization_id.irr,termObj.transactionsArr,item.recurring_payment_value);
                    item.payOut = item.contract_xnpv;
                    item.term = termObj.term;
                    item.term_numeric = termObj.term_numeric;
                    item.repayment_schedule = termObj.transactionsArr;
                    return item;
                });
                dispatch({
                    type: ADDED_CART_OF_INVESTEE,
                    investee_cart_contracts: cart_contracts_added,
                    cart_contracts: total,
                    cart_contracts_total_count: res.data.count,
                    is_adding_cart_for_investee: false,
                });
                dispatch({
                    type: SET_MARKET_COMPANY_VIEW,
                    marketCompanyView: 'cart',
                });
            } else {
                showNotification('error', res.data.message);
                dispatch({
                    type: ADDING_CART_FOR_INVESTEE,
                    is_adding_cart_for_investee: false,
                });
            }
        });
    };
}

export const setMarketCompanyView = (view) => {
    return { type: SET_MARKET_COMPANY_VIEW, marketCompanyView: view };
};

export const setMarketContractView = (view) => {
    return { type: SET_MARKET_CONTRACT_VIEW, marketContractView: view };
};

export const fetchAutoInvestForInvestor = (investor_organization_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_ALL_AUTOINVEST,
            is_fetching_autoinvest: true,
        });
        //call api to get autoinvest data
        callApi(`/autoInvest/investorOrganization/get_strategies`, 'post', {
            investor_organization_id,
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                dispatch({
                    type: FETCH_ALL_AUTOINVEST,
                    autoinvest: res.data,
                    autoinvest_total_count: res.count,
                    is_fetching_autoinvest: false,
                });
            } else {
                dispatch({
                    type: FETCH_ALL_AUTOINVEST,
                    is_fetching_autoinvest: false,
                });
                showNotification('error', 'Some error occured');
            }
        });
    };
};

export const fetchDrawdownOrders = (input) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: FETCHING_DRAWDOWN_ORDERS,
                data: {
                    isFetchingDrawdownOrders: true,
                },
            });
            return await getInvestorDrawdowns({
                input,
                onSuccess: (res) => {
                    dispatch({
                        type: FETCH_DRAWDOWN_ORDERS,
                        data: {
                            drawdownOrders: {
                                list: res,
                                id: input?.investor_organization_id,
                            },
                            isFetchingDrawdownOrders: false,
                        },
                    });
                },
            });
        } catch (err) {
            captureMessagesViaErrorLogger(err.toString());
            showNotification('Error', 'Some error occurred');
        } finally {
            dispatch({
                type: FETCHING_DRAWDOWN_ORDERS,
                data: {
                    isFetchingDrawdownOrders: false,
                },
            });
        }
    };
};

export function fetchFinancesOverviewData(investor_organization_id, input) {
    const currentDate = new Date();
    const defaultDateObj = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        startDate: 1,
        endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate(),
        isHead: true,
    };

    const datePayload =
        input && input.dateObj
            ? {
                  year: input.dateObj.year,
                  month: input.dateObj.month,
                  startDate: input.dateObj.startDate,
                  endDate: input.dateObj.endDate,
              }
            : {
                  year: defaultDateObj.year,
                  month: defaultDateObj.month,
                  startDate: defaultDateObj.startDate,
                  endDate: defaultDateObj.endDate,
              };

    return (dispatch) => {
        dispatch({
            type: FETCHING_OVERVIEW_DATA,
            isFetchingFinancesOverviewData: true,
        });
        const body = {
            investor_organization_id,
        };
        body.dateObj = datePayload;
        body.drawdowns = input?.drawdowns ?? 'Last 6 months';
        body.payments = input?.payments ?? 'Upcoming 12 months';
        return callApi(`investororganization/finance-transaction-overview`, 'post', body)
            .then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_OVERVIEW_DATA,
                        aggregate_repayment_details: res.data.repaidDetails,
                        previous_repayment_dates: res.data.previousRepaymentDates,
                        repayment_date_range: input?.dateObj ?? defaultDateObj,
                        monthly_completed_payments:
                            res.data.overviewDetails.monthly_completed_payments,
                        monthly_scheduled_payments: res.data.monthly_scheduled_payments,
                        monthly_drawdowns_graph: res.data.monthly_drawdowns_graph,
                        monthly_payments_graph: res.data.overviewDetails.monthly_payments_graph,
                        recently_completed_drawdowns: res.data.recently_completed_drawdowns,
                        total_recently_completed_drawdowns_amount:
                            res.data.total_recently_completed_drawdowns_amount,
                        isFetchingFinancesOverviewData: false,
                        total_upcoming_scheduled_payments_amount:
                            res.data.overviewDetails.total_upcoming_scheduled_payments_amount,
                    });
                } else {
                    dispatch({
                        type: FETCHING_OVERVIEW_DATA,
                        isFetchingFinancesOverviewData: false,
                    });
                    const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                    showNotification(ERROR, message);
                }
            })
            .catch(() => {
                dispatch({
                    type: FETCHING_OVERVIEW_DATA,
                    isFetchingFinancesOverviewData: false,
                });
                showNotification(ERROR, 'Some error occurred!');
            });
    };
}
export function setRepaymentStatusFilter(filters) {
    return {
        type: SET_REPAYMENT_STATUS_FILTER,
        investor_repayment_filter: filters,
    };
}

export function fetchInvestorOpsDetailsForDeals(investorOrgId) {
    return async (dispatch) => {
        const investorOpsDetailsforDeals = await getInvestorOpsMapping({
            investorOrganizationId: investorOrgId,
        });
        dispatch({
            type: FETCH_INVESTOR_OPS_DETAILS,
            investorOpsDetailsforDeals,
        });
    };
}

// todo get filtered list api for tradebook page
// todo get filtered list api for trade
// todo add investor support request
// todo add investor stats
// todo add investor payments and payouts
