import { UPLOAD } from '../AddBankAccounts';
import styles from '../AddBankAccounts.module.scss';
import { getS3IconLink } from '../../../../../../../util/utility';
import { ASSET_LINKS, ICON_NAMES } from '../../../../../../../constants/assetLinks';
// import styles from '../'

function SomethingWentWrong({ openFinbox, setCurrentStatus }: any) {
    return (
        // <div>
        //     <div className={styles.FetchBtnBlue} onClick={openFinbox}>
        //         <img src="/assets/Fetch-blue.svg" alt="" className="mr-2" />
        //         <span>Fetch via NetBanking</span>
        //     </div>
        //     <div style={{ marginTop: '0.5rem' }} className="dflex">
        //         <div>
        //             <img
        //                 src={getS3IconLink(ICON_NAMES.INFO_RED)}
        //                 alt="red-info-icon"
        //                 className="mr-2"
        //                 width={12}
        //                 height={12}
        //             />
        //             <span style={{ color: '#D06D6D' }} className="fs12 fw5">
        //                 Something went wrong with last fetch.
        //             </span>
        //         </div>
        //         <div className={styles.UploadManuallyBlue} onClick={() => setCurrentStatus(UPLOAD)}>
        //             <span>Upload PDF manually</span>
        //         </div>
        //     </div>
        // </div>
        <div className="row">
            <div className="col-md-6">
                <button className={styles.netbanking} onClick={openFinbox}>
                    Fetch via NetBanking
                </button>
            </div>
            <div className="col-md-6" onClick={() => setCurrentStatus(UPLOAD)}>
                <button className={styles.manually}>Upload manually</button>
            </div>
        </div>
    );
}

export default SomethingWentWrong;
