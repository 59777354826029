import { createContext, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import {
    getEmail,
    getHubspotToken,
    getIfCurrentUserIsPrimaryUser,
    getInvesteeInfo,
    getInvesteeOrganizationInfo,
    getInvesteeOrganizationIsTallyConnector,
    getUserDetails,
    getUserId,
    getUserInvesteeOrganizationId,
} from '../../../../App/AppReducer';
import { fetchInvesteeWidgetData, updateTallyConnector } from '../../../../App/AppActions';
import { isEnvProd, showNotification } from '../../../../../util/utility';
import callApi from '../../../../../util/apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchFinboxRedirectUrl, fetchKycDocs } from '../../../InvesteeActions';
import { Mixpanel } from '../../../../../util/mixpanel';
import { STEP_NAMES } from '../../../components/UploadDataJourney/utils/constants';
import {
    fetchMonthlyViewDataHelper,
    fetchOnboardingDataHelper,
    getFundingCalculatorValues,
} from '../../../common/apiHelpers';
import { initializeMonthlyViewData } from '../../../components/UploadDataJourney/utils/uploadJourneyUtils';
import { INVOICING } from '../../../components/UploadDataJourney/components/Invoicing/Invoicing';
import { MIS } from '../../../components/UploadDataJourney/components/MISUpload';
import { VerifyBusinessChecks } from '../../../components/UploadDataJourney/utils/checks';
import {
    API_STATUS,
    CRORE,
    GENERIC_ERROR,
    SHOW_NOTIFICATION_STATUS,
} from '../../../../../enums/appEnums';
import { finboxBankStatementMonths } from '../../../components/AddBankAccount/consts/consts';
import { DEBT_SCHEDULE } from '../../AddOnLimitPage/components/NeedInfo/consts/consts';
import { ACCOUNT_NUMBER, BANK_NAME } from '../components/BankAccounts/constants/consts';
import { isEqual } from 'lodash';
import { fetchUpcomingPaymentDetails } from '../../../utility/utility';
import {
    callAptToFetchCapitalAvailableData,
    fetchOnboardingData,
} from '../../../../../common/ApiContainer';

dayjs.extend(UTC);

export const VERIFICATION_STATUS = {
    NOT_VERIFIED: 'Not Verified',
    UNDER_VERIFICATION: 'Under Verification',
    VERIFIED: 'Verified',
    INVALID: 'Invalid',
};

export const defaultCards = [
    {
        heading: 'Upload Data',
        subHeading: 'Submit banking, GST & financial data',
        isDone: false,
        isOngoing: true,
        isAvailable: false,
        isActive: true,
    },
    {
        heading: 'Capital Offers',
        subHeading: 'Review the terms given by investor(s)',
        isDone: false,
        isOngoing: false,
        isAvailable: false,
        isActive: false,
    },
    {
        heading: 'KYC and Docs',
        subHeading: 'Submit your KYC docs and sign sanction',
        isDone: false,
        isOngoing: false,
        isAvailable: false,
        isActive: false,
    },
    {
        heading: 'Draw Capital',
        subHeading: 'Trade your contracts to draw capital',
        isDone: false,
        isOngoing: false,
        isAvailable: false,
        isActive: false,
    },
];

export const checkIfAllGstDataIsFetched = (isVerifyBusinessComplete, GSTs) => {
    if (!isVerifyBusinessComplete) return false;
    if (!GSTs) return true;
    for (let gst in GSTs) if (!GSTs[gst].user_id && !GSTs[gst].password) return false;
    return true;
};

const ZOHO_INTEGRATION_URL = 'integrations/zoho?path=Onboarding';

export const Context = createContext(null);

export const InvesteeOnboardingContext = ({ children, onBoardingData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => getUserDetails(state));
    const investeeOrgId = useSelector((state) => getUserInvesteeOrganizationId(state));
    const orgId = useSelector(getInvesteeOrganizationInfo)?._id;
    const companyInfo = useSelector(getInvesteeOrganizationInfo);
    const isPrimaryUser = useSelector(getIfCurrentUserIsPrimaryUser);
    const investeeeInfo = useSelector(getInvesteeInfo);
    const emailID = useSelector(getEmail);
    const [expandedAccount, setExpandedAccount] = useState('');
    const isTallyConnector = useSelector(getInvesteeOrganizationIsTallyConnector);
    const userId = useSelector(getUserId);
    const investeeOnboardingId = useSelector(
        (state) => getInvesteeOrganizationInfo(state)?.investee_onboarded_id,
    );
    const [annualRevenue, setAnnualRevenue] = useState('');
    const [capitalRequired, setCapitalRequired] = useState();
    const hubspotToken = useSelector((state) => getHubspotToken(state));

    const [currentStep, setCurrentStep] = useState(null);
    const initialRender = useRef(false);

    const [isFetchingOnboardingData, setIsFetchingOnboardingData] = useState(false);
    const [onboardingData, setOnboardingData] = useState(null);

    const [fetchingMonthlyViewData, setFetchingMonthlyViewData] = useState(false);
    const [monthlyViewData, setMonthlViewData] = useState({
        availableBalanceSheetMonthlyDates: {},
        availableBankTrxMonthlyDates: {},
        availableCashflowMonthlyDates: {},
        availableInvoiceGstMonthlyDates: {},
        availableInvoiceMonthlyDates: {},
        availablePnlMonthlyDates: {},
        bankStatementDates: {},
        invoicing_complete_months: 0,
        gst_complete_months: 0,
        pnl_complete_months: 0,
        cashflow_complete_months: 0,
        balance_sheet_complete_months: 0,
    });

    const isAppStarted = onboardingData?.is_application_started;
    const status = onboardingData?.status;
    const isApplicationSubmitted = onboardingData?.is_application_submitted;

    useEffect(() => {
        (async () => {
            // If integrating..
            await updateIntegration();
            // fetchOnboardingDetails..
            fetchOnboardingDetails();
            fetchMonthlyViewData();
            // fetchFinboxRedirectUrl..
            const fromDate = dayjs()
                .utcOffset(330)
                .subtract(finboxBankStatementMonths, 'months')
                .format('01/MM/YYYY');
            const toDate = dayjs().utcOffset(330).subtract(1, 'days').format('DD/MM/YYYY');
            dispatch(fetchFinboxRedirectUrl(fromDate, toDate, investeeOrgId));
            dispatch(fetchKycDocs(investeeOnboardingId));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investeeOrgId, investeeOnboardingId]);

    const updateIntegration = async () => {
        const ZOHO_INTEGRATION_URL = `integrations/zoho?path=Onboarding`;
        const query = new URLSearchParams(window.location.search);
        // const misInt = cookies.get('misIntegrations');
        // const invoicingInt = cookies.get('invoicingIntegrations');
        const misInt = query.get('misIntegrations');
        const invoicingInt = query.get('invoicingIntegrations');
        const code = query.get('code');
        const zohoIntLocation = query.get('location');
        const realmId = query.get('realmId');
        const error = query.get('error');

        let apiUrl;
        let body = {
            purpose: 'Register',
            data: {
                refresh_token: code,
                username: investeeOrgId,
                investee_onboarded_id: investeeOnboardingId,
            },
        };

        if (error) {
            const message = error ?? "Couldn't integrate, some error occurred!";
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            navigate({ pathname: window.location.pathname, search: '' });
            return;
        } else {
            if (misInt) {
                body.integration = 'mis';
                if (misInt === 'zoho') {
                    apiUrl = ZOHO_INTEGRATION_URL;
                    body.data.location = zohoIntLocation;
                    query.delete('location');
                    query.delete('accounts-server');
                }
                query.delete('misIntegrations');
                query.delete('code');
            } else if (invoicingInt) {
                body.integration = 'invoicing';
                if (invoicingInt === 'zoho') {
                    apiUrl = ZOHO_INTEGRATION_URL;
                    body.data.location = zohoIntLocation;
                    query.delete('location');
                    query.delete('accounts-server');
                }
                query.delete('invoicingIntegrations');
                query.delete('code');
            }
            if (apiUrl) {
                try {
                    const response = await callApi(apiUrl, 'post', body);
                    if (response.status !== API_STATUS.SUCCESS) {
                        const message = response.data?.message ?? GENERIC_ERROR;
                        console.error('Integration Error: ', message);
                    }
                } catch (err) {
                    console.error('Integration Error: ', err);
                } finally {
                    fetchOnboardingDetails();
                    navigate({
                        pathname: window.location.pathname,
                        search: query.toString(),
                    });
                }
            }
        }
    };

    const fetchOnboardingDetails = async (hasVerifyButtonClicked) => {
        const integrations = ['zoho', 'tally', 'razorpay'];
        setIsFetchingOnboardingData(true);
        await fetchOnboardingDataHelper({
            investeeOnboardingId,
            onSuccess: (data) => {
                data.misIntegration = '';
                data.invoicingIntegration = '';
                integrations.forEach((type) => {
                    if (data?.integration_mis?.[type]) data.misIntegration = type;
                    if (data?.integration_invoicing?.[type]) data.invoicingIntegration = type;
                });
                setOnboardingData(data);
                if (!initialRender.current) {
                    intialiseJourneyStep(data);
                    initialRender.current = true;
                }
            },
            onError: (err) => {
                showNotification('Error', err);
            },
        });
        setIsFetchingOnboardingData(false);
    };

    const intialiseJourneyStep = (data) => {
        if (VerifyBusinessChecks.isStepComplete(data)) setCurrentStep(STEP_NAMES.REVIEW);
    };

    const fetchMonthlyViewData = async () => {
        setFetchingMonthlyViewData(true);
        await fetchMonthlyViewDataHelper({
            investeeOrgId,
            onSuccess: (data) => {
                initializeMonthlyViewData(data, setMonthlViewData);
            },
            onError: (err) => {
                showNotification('Error', err);
            },
        });
    };

    const onSubmit = async (value, isAppStarted = false, isCapitalCalculatedKey = false) => {
        let paymentDetails = null;
        let customerType = null;

        const res = await fetchUpcomingPaymentDetails(investeeOnboardingId);
        if (res) {
            paymentDetails = res?.totalOverdueAmount > 0;
        }

        const capitalAmount = await callAptToFetchCapitalAvailableData(investeeOnboardingId);
        if (capitalAmount) {
            customerType = capitalAmount?.data?.data?.drawnAmount > 0 ? 'Customer' : 'Onboarding';
        }

        const Rev = await getFundingCalculatorValues({
            investeeOnboardingId,
            onSuccess: (res) => {
                const toNumber = Number(res.data.arr_funding_cal.toFixed(7)) * CRORE; //converting to INR from CR
                const arrFunding = toNumber.toLocaleString('en-In');
                if (toNumber > 0) {
                    setAnnualRevenue(arrFunding);
                } else {
                    setAnnualRevenue('');
                }
            },
        });

        const response = await fetchOnboardingData(investeeOnboardingId);
        if (response.status === 200) {
            setCapitalRequired(response.data?.data?.investeeOnboardingData?.capital_required);
        } else {
            setCapitalRequired('');
        }

        let key = 'is_application_submitted';
        if (isAppStarted) key = 'is_application_started';
        else if (isCapitalCalculatedKey) key = 'is_capital_calculated';
        let obj = {
            user: {
                user_name: userDetails.contact_name,
                user_email: userDetails.contact_email,
            },
        };
        obj[key] = value;
        // setIsFetchingDetails(true);
        callApi(
            `investeeOnboarding/${investeeOnboardingId}/v3/metadata-update?stage=stage3`,
            'post',
            obj,
        )
            .then((res) => {
                if (res.status === 'Success') {
                    if (key === 'is_application_started') {
                        if (isEnvProd) {
                            Mixpanel.identify(
                                res.data?.investeeOnboardingData?.investee_organization_id,
                            );
                            Mixpanel.people.set({
                                application_started_at: new Date(),
                                ip_address: window.localStorage.getItem('myIp'),
                            });
                            Mixpanel.track('Application Started', {
                                Source: 'Onboarding',
                                ip_address: window.localStorage.getItem('myIp'),
                                'Company Name': companyInfo?.name,
                                Email: emailID,
                                'Job Role': investeeeInfo?.designation,
                                Revenue: annualRevenue,
                                'Capital Expectation': capitalRequired?.amount,
                                Timeline: capitalRequired?.date_by,
                                isActive: companyInfo?.is_company_active,
                                isDPD: paymentDetails,
                                Customer: customerType,
                                isPrimaryUser: isPrimaryUser,
                            });
                        }
                    } else if (key === 'is_application_submitted') {
                        if (isEnvProd) {
                            Mixpanel.identify(
                                res.data?.investeeOnboardingData?.investee_organization_id,
                            );
                            Mixpanel.people.set({
                                application_submitted_at: new Date(),
                                ip_address: window.localStorage.getItem('myIp'),
                            });
                            Mixpanel.track('Application Submitted', {
                                ip_address: window.localStorage.getItem('myIp'),
                                distinct_id: orgId,
                                orgId: orgId,
                                userId: userId,
                                isActive: companyInfo?.is_company_active,
                                isDPD: paymentDetails,
                                Customer: customerType,
                                isPrimaryUser: isPrimaryUser,
                                'Job Role': investeeeInfo?.designation,
                            });
                        }
                    }
                    setTimeout(() => dispatch(fetchInvesteeWidgetData(userId)), 800); // to fetch widget data coz kyc coverage might have updated
                    setOnboardingData(res.data?.investeeOnboardingData);
                } else {
                    showNotification('Error', 'Some error occurred!');
                }
                // setIsFetchingDetails(false);
            })
            .catch(() => {
                // setIsFetchingDetails(false);
                showNotification('Error', 'Some error occurred!');
            });
    };

    const updateDataAttachments = (keyName, fileWithErrors, deletedDocId) => {
        if (deletedDocId) {
            handleDocDeletion(keyName, deletedDocId);
        } else {
            if (keyName === INVOICING)
                setOnboardingData((data) => ({
                    ...data,
                    invoicing: {
                        ...(data?.invoicing ?? {}),
                        file_arr: [...(data?.invoicing?.file_arr ?? []), { ...fileWithErrors }],
                    },
                }));
            else if (keyName === MIS)
                setOnboardingData((data) => ({
                    ...data,
                    mis: {
                        ...(data?.mis ?? {}),
                        file_arr: [...(data?.mis?.file_arr ?? []), { ...fileWithErrors }],
                    },
                }));
            else if (keyName === DEBT_SCHEDULE)
                setOnboardingData((data) => ({
                    ...data,
                    debtSchedule: {
                        ...(data?.debtSchedule ?? {}),
                        file_arr: [...(data?.debtSchedule?.file_arr ?? []), { ...fileWithErrors }],
                    },
                }));
        }
    };

    const handleDocDeletion = (keyName, deletedDocId) => {
        if (keyName === INVOICING) {
            const attachments = onboardingData?.invoicing?.file_arr ?? [];
            let updatedAttachments = attachments.filter((file) => file?._id !== deletedDocId);
            setOnboardingData((data) => ({
                ...data,
                invoicing: {
                    ...(data?.invoicing ?? {}),
                    file_arr: updatedAttachments,
                },
            }));
        } else if (keyName === MIS) {
            const attachments = onboardingData?.mis?.file_arr ?? [];
            let updatedAttachments = attachments.filter((file) => file?._id !== deletedDocId);
            setOnboardingData((data) => ({
                ...data,
                mis: {
                    ...(data?.mis ?? {}),
                    file_arr: updatedAttachments,
                },
            }));
        }
    };

    const toggleTallyConnector = (value) => {
        dispatch(
            updateTallyConnector(investeeOrgId, {
                investeeorganization: { tally_connector: value },
            }),
        );
    };

    const updateOnboardingData = (updatedData) => {
        const newBankAccountData = updatedData?.bank_account ?? {};
        const oldBankAccountData = onboardingData?.bank_account ?? {};
        let newAddedAccount = '';
        for (let accounts in newBankAccountData) {
            if (accounts !== 'is_add_bank_account') {
                if (!(accounts in oldBankAccountData)) {
                    const cat =
                        newBankAccountData[accounts][BANK_NAME] +
                        ' - ' +
                        newBankAccountData[accounts][ACCOUNT_NUMBER]?.replace(/\d(?=\d{4})/g, '*');
                    newAddedAccount = cat;
                    break;
                } else {
                    const newBankDetails = newBankAccountData[accounts];
                    const oldBankDetails = oldBankAccountData[accounts];
                    const isBankDetailsNotSame = !isEqual(newBankDetails, oldBankDetails);
                    if (isBankDetailsNotSame) {
                        const cat =
                            newBankAccountData[accounts][BANK_NAME] +
                            ' - ' +
                            newBankAccountData[accounts][ACCOUNT_NUMBER]?.replace(
                                /\d(?=\d{4})/g,
                                '*',
                            );
                        newAddedAccount = cat;
                        break;
                    }
                }
            }
        }
        setExpandedAccount(newAddedAccount);
        setOnboardingData(updatedData);
    };

    const updateOnboardingDataByKey = (key, updatedData) => {
        setOnboardingData((data) => ({
            ...data,
            [key]: updatedData,
        }));
    };

    return (
        <Context.Provider
            value={{
                ...onBoardingData,
                userId,
                status,
                onSubmit,
                currentStep,
                isAppStarted,
                investeeOrgId,
                onboardingData,
                expandedAccount,
                setCurrentStep,
                monthlyViewData,
                isTallyConnector,
                setOnboardingData,
                fetchMonthlyViewData,
                investeeOnboardingId,
                isApplicationSubmitted,
                updateDataAttachments,
                setExpandedAccount,
                toggleTallyConnector,
                fetchOnboardingDetails,
                updateOnboardingData,
                updateOnboardingDataByKey,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useInvesteeOnboardingContext = () => {
    return useContext(Context);
};
