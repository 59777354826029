import React, { DragEventHandler, useState } from 'react';

import Spinner from './Spinner';
import styles from '../UploadCard.module.scss';
import { showNotification } from '../../../../../../../util/utility';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../../../enums/appEnums';
import UploadingCloud from './UploadingCloud';
import { getInputAcceptValue } from '../utils/uploadDocUtils';
import { OTHERS, additionalOption } from '../../../../../../../constants/consts';
import DragDropUpload from '../../../../../../../components/DragDropUpload';

interface AdditionalDataUploadProps {
    category: string;
    keyName: string;
    files: any;
    provideOptionToAddData: boolean;
    description: string;
    isUploading: boolean;
    isDownloading: string | null;
    inputFileRef: any;
    mainText: string;
    mainTextOptional: string | undefined;
    otherValue: string;
    setOtherValue: Function;
    deleteFile: Function;
    downloadFile: Function;
    setDescription: Function;
    onFileChangeCapture: Function;
    setProvideOptionToAddData: Function;
    handleDrag: DragEventHandler<HTMLDivElement>;
    handleDrop: DragEventHandler<HTMLDivElement>;
    isDataVault: boolean;
}

function AdditionalDataUpload({
    category,
    keyName,
    files,
    provideOptionToAddData,
    description,
    isUploading,
    isDownloading,
    inputFileRef,
    mainText,
    mainTextOptional,
    deleteFile,
    downloadFile,
    setDescription,
    onFileChangeCapture,
    setProvideOptionToAddData,
    handleDrag,
    handleDrop,
    otherValue,
    setOtherValue,
    isDataVault,
}: AdditionalDataUploadProps) {
    const checkDescThenCapture = (e: any) => {
        if (description) {
            if (description === OTHERS) {
                if (otherValue) {
                    onFileChangeCapture(e);
                } else {
                    inputFileRef.current.value = '';
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        'You need to enter description first!',
                    );
                }
            } else {
                onFileChangeCapture(e);
            }
        } else {
            inputFileRef.current.value = '';
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'You need to enter description first!',
            );
        }
    };

    return (
        <>
            {files?.map((file: any, index: number) => {
                if (!file.source) {
                    return (
                        <div className={styles.AdditionalData} key={index}>
                            <input
                                type="text"
                                className={styles.additionDataModalInput}
                                placeholder="Enter description"
                                value={file.description}
                                disabled
                                required
                                title={file.description}
                            />
                            <div className={styles.uploadedPDFWrapper}>
                                <div
                                    key={index}
                                    className={styles.UploadedPDF}
                                    style={{
                                        height: '44px',
                                        padding: '12px',
                                        marginTop: 0,
                                    }}
                                >
                                    <span
                                        className={`${styles.textTruncate} ${styles.downloadable}`}
                                        title={file.doc_name}
                                        onClick={() => downloadFile(file.doc_link, file.doc_name)}
                                    >
                                        {file.doc_name}
                                    </span>
                                    {isDownloading && isDownloading === file.doc_link && (
                                        <Spinner />
                                    )}
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => deleteFile(file.doc_link, file._id)}
                                    >
                                        <img src="/assets/clear-blue.svg" alt="" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
            {provideOptionToAddData || !files?.length ? (
                <>
                    <div className={styles.AdditionalData}>
                        <select
                            className={styles.AdditionDataModalSelect}
                            placeholder="Enter description"
                            value={description}
                            required
                            onChange={(e) => setDescription(e.target.value)}
                        >
                            <option value={''}>Select Description</option>
                            {additionalOption.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                        {description === OTHERS ? (
                            <input
                                required
                                type="text"
                                className={styles.additionDataModalInputBox}
                                placeholder="Description"
                                value={otherValue}
                                onChange={(e) => setOtherValue(e.target.value)}
                            />
                        ) : null}
                    </div>

                    <div className={styles.fullwidth}>
                        <input
                            type="file"
                            ref={inputFileRef}
                            onChangeCapture={checkDescThenCapture}
                            style={{ display: 'none' }}
                            accept={getInputAcceptValue(category, keyName)}
                        />
                        <div
                            className={styles.styledUploadContainerSingle}
                            onClick={() => inputFileRef.current.click()}
                            onDrop={handleDrop}
                            onDragOver={handleDrag}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                        >
                            <DragDropUpload />
                        </div>
                    </div>
                </>
            ) : null}
            {/* {isUploading && renderUploadLoader('250px', '122px')} */}
            {isUploading && <UploadingCloud width="250px" left="122px" />}
            <div className="row">
                <div className="col-6-md"></div>
                <div className="col-6-md r-mt-4">
                    <button
                        className={styles.additionalDoc}
                        onClick={() => setProvideOptionToAddData(true)}
                    >
                        <img src="/assets/plus-blue.svg" alt="" className="r-mr-2" />
                        <span>Add Additional Data</span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default AdditionalDataUpload;
