import React, { DragEvent, useEffect, useRef, useState } from 'react';
import {
    ACCOUNT_HOLDER_NAME,
    ACCOUNT_NUMBER,
    BANK_NAME,
    IFSC,
} from '../../components/AddBankAccounts/AddBankAccounts';
import styles from './UploadCard.module.scss';
import { useSelector } from 'react-redux';
import {
    getEmail,
    getInvesteeOrganizationInfoId,
    getInvestorInfo,
    getUserInvesteeOrganizationId,
    getUserName,
} from '../../../../../App/AppReducer';
import {
    customTimeout,
    fundingCalcAmount,
    getBucketNameFromS3Url,
    getFlowIdForMnemosyne,
    getFullFileNameForOnBoardingAndAddOn,
    getKeyFromS3Url,
    getcategoryIdForMnemosyne,
    showNotification,
} from '../../../../../../util/utility';
import { API_STATUS, FLOW_ID, SHOW_NOTIFICATION_STATUS } from '../../../../../../enums/appEnums';
import callApi from '../../../../../../util/apiCaller';
import {
    CATEGORIES,
    getFileExtension,
    getInputAcceptValue,
    handleFilesValidation,
    modifyFileNameBeforeUpload,
} from './utils/uploadDocUtils';
import {
    triggerFileIngestion,
    updateMetadata,
    getSignedUrl,
    uploadFile,
    deleteFile,
} from './utils/api/apiHelper';
import {
    getSignedUrlToUpload,
    saveDocMetaDataInDocService,
    updateODFDData,
    deleteFileFromDocService,
    getPresignedUrl,
    updateClaim,
    updateBalanceConfirmation,
} from '../../../../common/apiHelpers';
import { captureMessagesViaErrorLogger } from '../../../../../../util/errorLogger.js';
import AdditionalDataUpload from './components/AdditionalDataUpload';
import { UploadAnotherFile, UploadDocBox } from './components/UploadButtons';
import SingleFile from './components/SingleFile';
import { UPLOAD_STAGE } from '../constants';
import { INVOICING } from '../../components/Invoicing/Invoicing';
import MISMergePopup from './components/MISMergePopup';
import { ONBOARDING } from '../../components/BankAccounts/components/ODFD/ODFD';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import redInfoIcon from '../../../../../../assets/red-info.svg';
import { isEmpty } from 'lodash';
import { OTHERS } from '../../../../../../constants/consts';
import {
    ADDITIONAL_DOCS,
    MUTUAL_FUNDS,
} from '../../../../pages/AddOnLimitPage/components/NeedInfo/consts/consts';

function getLast15MonthsCount(monthsArray: any) {
    const currentDate = moment();
    const last15Months: any[] = [];

    for (let i = 0; i < 15; i++) {
        last15Months.push(
            currentDate.clone().subtract(i, 'months').format('MMM YYYY').toLowerCase(),
        );
    }

    const count = monthsArray.filter((month: any) =>
        last15Months.includes(month.toLowerCase()),
    ).length;

    return count;
}
export const BankStatementErrors = ({ fileErrors, item }: any) => {
    if (item === 'missingMonths') {
        const count = getLast15MonthsCount(fileErrors[item]);
        if (count > 0) {
            return (
                <div className={styles.BankstatementErrorWrapper}>
                    <img src={redInfoIcon} alt="red" height="12px" />
                    <p className={styles.bankStatementErrorMsg}>
                        {`Bank statements missing for`}
                        <span className={styles.monthErrorMsg}>{`${count} ${
                            count === 1 ? 'month' : 'months'
                        }.`}</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    }
    if (item === 'openingClosingMismatch') {
        return fileErrors[item].map((e: any) => {
            const startDate = moment(e.startMonth, 'MMM YYYY');
            const endDate = moment(e.endMonth, 'MMM YYYY');

            const monthsDifference = endDate.diff(startDate, 'months');

            const startMonth = startDate.format('MMM YYYY');
            const endMonth = endDate.format('MMM YYYY');
            return (
                <div className={styles.BankstatementErrorWrapper}>
                    <img src={redInfoIcon} alt="red" height="12px" />
                    <p
                        className={styles.bankStatementErrorMsg}
                    >{`Closing balance of ${startMonth} doesn’t match with opening balance of ${endMonth}, please re-upload statement for these ${monthsDifference} ${
                        monthsDifference == 1 ? 'month' : 'months'
                    }`}</p>
                </div>
            );
        });
    }
    if (item === 'invalidFiles') {
        return (
            <div className={styles.BankstatementErrorWrapper}>
                <img src={redInfoIcon} alt="red" height="12px" />
                <p className={styles.bankStatementErrorMsg}>
                    {`Please upload original File (scanned file not supported) by removing the password `}
                    <span className={styles.fileNameText} data-tip="" data-for="add-bank-btn">
                        (file name)
                    </span>
                </p>
                <ReactTooltip
                    id="add-bank-btn"
                    type="light"
                    place="bottom"
                    className={styles.bankAccountErrorTooltip}
                    border={true}
                    borderColor="black"
                    delayShow={50}
                    multiline={true}
                >
                    <ol className={styles.orderListWrapper}>
                        {fileErrors[item].map((item: any) => {
                            return <li>{item}</li>;
                        })}
                    </ol>
                </ReactTooltip>
            </div>
        );
    }
    if (item === 'accountNumberValidation') {
        if (
            Object.keys(fileErrors?.[item])?.length > 0
            // fileErrors[item]?.accountNumberFromVendor &&
            // fileErrors[item]?.accountNumberFromUser &&
            // fileErrors[item]?.accountNumberFromVendor !== fileErrors[item]?.accountNumberFromUser
        ) {
            // return (
            //     <div className={styles.BankstatementErrorWrapper}>
            //         <img src={redInfoIcon} alt="red" height="12px" />
            //         <p
            //             className={styles.bankStatementErrorMsg}
            //         >{`Entered account number doesn’t match with the file.`}</p>
            //     </div>
            // );
            return (
                <div className={styles.BankstatementErrorWrapper}>
                    <img src={redInfoIcon} alt="red" height="12px" />
                    <p className={styles.bankStatementErrorMsg}>
                        {`Entered account number doesn’t match with the file. `}
                        <span className={styles.fileNameText} data-tip="" data-for="add-bank-btn-1">
                            (file name)
                        </span>
                    </p>
                    <ReactTooltip
                        id="add-bank-btn-1"
                        type="light"
                        place="bottom"
                        className={styles.bankAccountErrorTooltip}
                        border={true}
                        borderColor="black"
                        delayShow={50}
                        multiline={true}
                    >
                        <ol className={styles.orderListWrapper}>
                            {Object.keys(fileErrors[item]).map((item: any, i: any) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ol>
                    </ReactTooltip>
                </div>
            );
        }
    }
    return null;
};

export default function UploadCard({
    onSuccess,
    hideDragDrop,
    isDsAdditionalDocs = false,
    isInvestor,
    onMetadataSuccess,
    stage,
    path,
    mainText,
    mainTextOptional,
    noOfFiles,
    deleteKeyName,
    keyName,
    category,
    files,
    needInfoCategory,
    bankDetails,
    accountNumber,
    hideUploadAnother = false,
    onDelete,
    isTally = false,
    investee_onboarded_id,
    renderComponent,
    allowMultiple = false,
    isMnemosyneFlow = false,
    truncateSmallerText = false,
    mixpanelForOdFDChecked,
    index,
    fileTypeAllowed,
    disabled,
    isDataVault,
    customerSupportQueryId,
    getTdsClaim,
    hideDeleteIcon = false,
    fileErrors,
    bankData,
    useMnemosyneUploadFlow = false,
    saveTOadditionalDocs = false,
    categoryId,
    type,
    mutualFundAmount,
}: any) {
    const countDownLimit = 25;
    const [totalRows, setTotalRows] = useState(0);
    const [acceptedRows, setAcceptedRows] = useState(0);
    const [params, setParams] = useSearchParams();
    const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [bankFileErrors, setBankFileErrors] = useState({});
    const [isDownloading, setIsDownloading] = useState<string | null>(null);
    const [disableDelButton, setDisableDelButton] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState<string | null>(null);
    const [otherValue, setOtherValue] = useState<string>('');
    const [description, setDescription] = useState('');
    const [provideOptionToAddData, setProvideOptionToAddData] = useState(false);
    const [showMisMergePopup, setShowMisMergePopup] = useState<any>(false);
    const [countDown, setCountDown] = useState(countDownLimit);
    const [startTimer, setStartTimer] = useState(false);
    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const callingApi = useRef(false);
    const investeeOrganizationId = useSelector(getUserInvesteeOrganizationId);
    const investorOrgId = useSelector(getInvestorInfo)?.investor_organization_id;
    const onboardingId = useSelector(getInvesteeOrganizationInfoId);
    const investeeOnboardingId = investee_onboarded_id ?? onboardingId;
    const userName = useSelector(getUserName);
    const emailAddress = useSelector(getEmail);
    const extensionCategory =
        path === UPLOAD_STAGE.ONBOARDING
            ? category === CATEGORIES.MIS
                ? CATEGORIES.PROVISIONAL_FINANCIALS
                : category
            : category;

    const handleClick = (e: any) => {
        if (e) e.stopPropagation();
        if (
            (category === CATEGORIES.TDS || category === CATEGORIES.TOTAL_REPAYMENTS) &&
            !isUploading
        ) {
            inputFileRef.current.click();
        }
        if (!disabled && category !== CATEGORIES.TDS && category !== CATEGORIES.TOTAL_REPAYMENTS)
            inputFileRef.current.click();
    };

    const fileTypeExtensionMap: Record<string, string> = {
        pdf: 'application/pdf',
    };

    /**
     * splitting the mnemosyne and core flow for upload document
     */
    useEffect(() => {
        if (callingApi.current) {
            if (!isMnemosyneFlow) {
                handleSubmission();
            } else {
                handleSubmissionMnemosyne();
            }
        }
        // eslint-disable-next-line
    }, [selectedFiles]);

    const getFlowId = () => {
        switch (category) {
            case CATEGORIES.FD:
            case CATEGORIES.OD:
                return 3;
            case CATEGORIES.TDS:
            case CATEGORIES.CP:
                return 1;
            case CATEGORIES.CUSTOMER_SUPPORT_OTHER:
            case CATEGORIES.CUSTOMER_SUPPORT:
            case CATEGORIES.TOTAL_REPAYMENTS:
                return 5;
            default:
                return 1;
        }
    };

    const onFileChangeCapture = async (e: any, isDropped = false) => {
        const filesArr = isDropped ? [...e.dataTransfer.files] : [...e.target.files];
        const { filteredFiles } = handleFilesValidation(filesArr, category, keyName);
        if (filteredFiles.length === 0) {
            setIsUploading(false);
            return;
        }
        if (
            category === CATEGORIES.TDS ||
            category === CATEGORIES.DEBT_SCHEDULE ||
            category === CATEGORIES.TOTAL_REPAYMENTS
        ) {
            if (filteredFiles.length > 1) {
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Upload only one file at a time');
                return;
            }
        }
        if (category === CATEGORIES.CUSTOMER_SUPPORT_OTHER && noOfFiles > 10) {
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                `Cannot upload more than ${noOfFiles} files`,
            );
            return;
        }
        let filesToBeMapped = filteredFiles;
        const files = filesToBeMapped?.map((file, index) => {
            return {
                file: file,
                id: index + 1,
                isUploading: true,
                uploadProgress: 0,
            };
        });
        if (category === CATEGORIES.MIS && path !== UPLOAD_STAGE.ONBOARDING) {
            //if mis files are uploaded, show the overwrite disclaimer
            setShowMisMergePopup(files);
        } else {
            callingApi.current = true;
            setSelectedFiles(files);
        }
    };

    useEffect(() => {
        if (startTimer) {
            const interval = setInterval(() => {
                setCountDown((prevCountDown) => prevCountDown - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setCountDown(countDownLimit);
        }
    }, [startTimer]);

    const handleSubmission = async () => {
        const selected_files = [...selectedFiles];
        setIsUploading(true);
        let index = 0;
        callingApi.current = false;
        for (const fileObj of selected_files) {
            const { file, id } = fileObj as { file: File; id: number };
            const splitter = file.name.split('.');

            let file_name = modifyFileNameBeforeUpload(file.name, category, isTally);
            const fileNameInput = {
                category,
                bankName: bankData?.[BANK_NAME],
                bankAccountNumber: bankData?.[ACCOUNT_NUMBER],
                docName: description === OTHERS ? otherValue : description,
                odAmount: fundingCalcAmount(bankData?.od_amount),
                fdAmount: fundingCalcAmount(bankData?.fd_amount),
                mutualFundAmount: fundingCalcAmount(mutualFundAmount),
                fullFileName: file_name,
            };

            const input = {
                stage:
                    keyName === 'additional_data' && path === UPLOAD_STAGE.ONBOARDING
                        ? 'stage1'
                        : 'stage2',
                sub_stage: isDsAdditionalDocs ? 'dataUploads' : category,
                _id: investeeOnboardingId ?? investorOrgId,
                fileName: getFullFileNameForOnBoardingAndAddOn(fileNameInput),
                file_type: file.type,
                extension: splitter[splitter.length - 1],
                investee_organization_id: investeeOrganizationId ?? investorOrgId,
                path,
            };
            if (stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS) {
                setSelectedFiles((prev) => {
                    return prev.map((item, i) => ({
                        ...item,
                        ...(i === index && {
                            uploadProgress: 0,
                        }),
                    }));
                });
            } else {
                setSelectedFiles((files) =>
                    files.map((file, i) => ({
                        ...file,
                        ...(i === 0 && {
                            uploadProgress: 0,
                        }),
                    })),
                );
            }

            if (useMnemosyneUploadFlow) {
                await uploadMnemosyneOrganizationDocs(index, id, file, input);
            } else {
                await uploadDocument(index, id, file, input);
            }
            index++;
        }
    };

    const getFileNameFromSignedUrl = (preSignedUrl: string) => {
        if (saveTOadditionalDocs) {
            return decodeURIComponent(preSignedUrl)?.split(`${ADDITIONAL_DOCS}/`)[1]?.split('?')[0];
        } else if (keyName === MUTUAL_FUNDS) {
            return decodeURIComponent(preSignedUrl)?.split(`${MUTUAL_FUNDS}/`)[1]?.split('?')[0];
        }
        return decodeURIComponent(preSignedUrl)?.split(`${category}/`)[1]?.split('?')[0];
    };

    const uploadMnemosyneOrganizationDocs = async (
        index: number,
        id: number,
        file: File,
        input: any,
    ) => {
        try {
            const data = { url: '' };
            const { signedUrl, docTypeId } = await getSignedUrlToUpload({
                input: {
                    categoryId:
                        categoryId ??
                        getcategoryIdForMnemosyne(
                            saveTOadditionalDocs ? ADDITIONAL_DOCS : category,
                        ),
                    fileName: input.fileName,
                    dk1: investeeOrganizationId,
                    dk2: path,
                    createOrgIdFolderAtRoot: true,
                },
                orgId: investeeOrganizationId,
            });

            await customTimeout(500, () => {
                if (stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS) {
                    setSelectedFiles((prev) => {
                        return prev.map((item, i) => ({
                            ...item,
                            ...(i === index && {
                                uploadProgress: 20,
                            }),
                        }));
                    });
                } else {
                    setSelectedFiles((files) =>
                        files.map((file, i) => ({
                            ...file,
                            ...(i === 0 && {
                                uploadProgress: 20,
                            }),
                        })),
                    );
                }
            });

            data.url = signedUrl;
            const requestOptions = {
                method: 'put',
                body: file,
                headers: new Headers({
                    'Content-Type': file.type,
                }),
            };

            if (data.url) {
                const uploadResponse: any = await fetch(data.url, requestOptions);

                if (uploadResponse.ok) {
                    await customTimeout(500, () => {
                        if (
                            stage === UPLOAD_STAGE.ONBOARDING &&
                            category === CATEGORIES.BANK_STATEMENTS
                        ) {
                            setSelectedFiles((prev) => {
                                return prev.map((item, i) => ({
                                    ...item,
                                    ...(i === index && {
                                        uploadProgress: 40,
                                    }),
                                }));
                            });
                        } else {
                            setSelectedFiles((files) =>
                                files.map((file, i) => ({
                                    ...file,
                                    ...(i === 0 && {
                                        uploadProgress: 40,
                                    }),
                                })),
                            );
                        }
                    });
                    const mnemosyneInput = {
                        categoryId:
                            categoryId ??
                            getcategoryIdForMnemosyne(
                                saveTOadditionalDocs ? ADDITIONAL_DOCS : category,
                            ),
                        fileName: getFileNameFromSignedUrl(data.url),
                        fileSizeKb: file.size / 1_000,
                        fileType: file.type,
                        uploadedBy: userName,
                        emailAddress: emailAddress,
                        flowId: getFlowIdForMnemosyne(path),
                        docTypeId: docTypeId,
                        description: '',
                        dk1: investeeOrganizationId,
                        dk2: path,
                        createOrgIdFolderAtRoot: true,
                    };

                    const docData = await saveDocMetaDataInDocService({
                        input: mnemosyneInput,
                        orgId: investeeOrganizationId,
                    });

                    await customTimeout(500, () => {
                        if (
                            stage === UPLOAD_STAGE.ONBOARDING &&
                            category === CATEGORIES.BANK_STATEMENTS
                        ) {
                            setSelectedFiles((prev) => {
                                return prev.map((item, i) => ({
                                    ...item,
                                    ...(i === index && {
                                        uploadProgress: 60,
                                    }),
                                }));
                            });
                        } else {
                            setSelectedFiles((files) =>
                                files.map((file, i) => ({
                                    ...file,
                                    ...(i === 0 && {
                                        uploadProgress: 60,
                                    }),
                                })),
                            );
                        }
                    });
                    const docId = docData?.id;
                    if (docId) {
                        const data_to_post: any = {};
                        if (keyName === 'mutualFunds') {
                            data_to_post[keyName] = {
                                doc_link: docId,
                                doc_name: input.fileName,
                            };
                            data_to_post.type = keyName;
                            data_to_post.document_details = {
                                mutual_funds_amount: mutualFundAmount,
                            };
                        } else {
                            data_to_post[keyName] = {};
                            data_to_post[keyName].doc_link = docId;
                            data_to_post[keyName].doc_name = input.fileName;
                            data_to_post[keyName].signedUrl = data.url?.split('?')[0];
                        }
                        // if (addnId) {
                        //     data_to_post[keyName]._id = addnId;
                        // }
                        data_to_post[keyName].source = '';
                        if (type) data_to_post.type = type;
                        if (keyName === CATEGORIES.ADDITIONAL_DATA) {
                            data_to_post[keyName].description =
                                description === 'Others' ? otherValue : description;
                            setDescription('');
                            setOtherValue('');
                            if (provideOptionToAddData) setProvideOptionToAddData(false);
                        }

                        if (keyName === CATEGORIES.BANK_STATEMENTS) {
                            data_to_post.account_number = accountNumber;
                            if (bankDetails && index === 0) {
                                data_to_post.bank_account = {};
                                data_to_post.bank_account[BANK_NAME] = bankDetails[BANK_NAME];
                                data_to_post.bank_account[ACCOUNT_HOLDER_NAME] =
                                    bankDetails[ACCOUNT_HOLDER_NAME];
                                data_to_post.bank_account[ACCOUNT_NUMBER] =
                                    bankDetails[ACCOUNT_NUMBER];
                                data_to_post.bank_account[IFSC] = bankDetails[IFSC];
                                data_to_post.bank_account.account_nick_name =
                                    bankDetails.bank_name + ' ' + accountNumber?.slice(-4);
                            }
                        }

                        let fileParseErrors: any = null;

                        const isPdf = getFileExtension(file.name) === 'pdf';
                        if (
                            (category === CATEGORIES.INVOICING ||
                                category === CATEGORIES.MIS ||
                                category === CATEGORIES.DEBT_SCHEDULE) &&
                            !isTally &&
                            !isPdf
                        ) {
                            const fileInfo: any = {
                                s3: {
                                    bucket: getBucketNameFromS3Url(data.url),
                                    key: getKeyFromS3Url(data.url),
                                },
                            };
                            if (
                                !(
                                    stage === UPLOAD_STAGE.ONBOARDING &&
                                    category === CATEGORIES.BANK_STATEMENTS
                                )
                            ) {
                                setIsUploading(false);
                            }

                            //Mocking File parsing
                            await customTimeout(500, () => {
                                if (
                                    stage === UPLOAD_STAGE.ONBOARDING &&
                                    category === CATEGORIES.BANK_STATEMENTS
                                ) {
                                    setSelectedFiles((item) => {
                                        return item.map((file, i) => ({
                                            ...file,
                                            ...(i === index && {
                                                uploadProgress: 95,
                                            }),
                                        }));
                                    });
                                } else {
                                    setSelectedFiles((files) =>
                                        files.map((file, i) => ({
                                            ...file,
                                            ...(i === 0 && {
                                                uploadProgress: 95,
                                            }),
                                        })),
                                    );
                                }
                            });
                            if (category === CATEGORIES.DEBT_SCHEDULE)
                                fileInfo.orgId = investeeOrganizationId;
                            const ingestionTriggerResponse: any = await triggerFileIngestion(
                                fileInfo,
                                category,
                            );
                            if (category === CATEGORIES.DEBT_SCHEDULE) {
                                const ingestionResponseData = ingestionTriggerResponse?.data?.data;
                                const accepted_rows = ingestionResponseData?.rowsParsed ?? '0';
                                const total_rows = ingestionResponseData?.totalRows ?? '0';
                                const rejected_rows = ingestionResponseData?.rowsRejected ?? '0';
                                if (ingestionTriggerResponse?.status === 200) {
                                    let parsedAcceptedRows = !isNaN(parseInt(accepted_rows))
                                        ? parseInt(accepted_rows)
                                        : null;
                                    let parsedTotalRows = !isNaN(parseInt(total_rows))
                                        ? parseInt(total_rows)
                                        : null;
                                    let parsedRejectedRows = !isNaN(parseInt(rejected_rows))
                                        ? parseInt(rejected_rows)
                                        : null;
                                    fileParseErrors = {
                                        is_file_empty:
                                            ingestionResponseData?.isFileEmpty ??
                                            ingestionResponseData?.isFileEmpty ??
                                            false,
                                        accepted_rows: parsedAcceptedRows,
                                        rejected_rows: parsedRejectedRows,
                                        total_rows: parsedTotalRows,
                                        errorFileUrl: ingestionResponseData?.errorFileUrl,
                                        missing_col:
                                            ingestionResponseData?.message ??
                                            ingestionResponseData?.message ??
                                            [],
                                        file_url: ingestionResponseData?.s3?.key,
                                        isParsed: ingestionResponseData?.statusCode === 20,
                                    };
                                    setAcceptedRows(parsedAcceptedRows ?? 0);
                                    setTotalRows(parsedTotalRows ?? 0);
                                }
                            } else {
                                const ingestionResponseData = ingestionTriggerResponse?.data?.data;
                                const accepted_rows =
                                    ingestionResponseData?.status?.accepted_rows ??
                                    ingestionResponseData?.status?.acceptedRowCount ??
                                    '0';
                                const rejected_rows =
                                    ingestionResponseData?.status?.rejected_rows ??
                                    ingestionResponseData?.status?.rejectedRowCount ??
                                    '0';
                                if (ingestionTriggerResponse?.status === 200) {
                                    fileParseErrors = {
                                        is_file_empty:
                                            ingestionResponseData?.status?.is_file_empty ??
                                            ingestionResponseData?.status?.isFileEmpty ??
                                            false,
                                        accepted_rows: !isNaN(parseInt(accepted_rows))
                                            ? parseInt(accepted_rows)
                                            : null,
                                        rejected_rows: !isNaN(parseInt(rejected_rows))
                                            ? parseInt(rejected_rows)
                                            : null,
                                        total_rows:
                                            !isNaN(parseInt(accepted_rows)) &&
                                            !isNaN(parseInt(rejected_rows))
                                                ? parseInt(accepted_rows) + parseInt(rejected_rows)
                                                : 0,
                                        missing_col:
                                            ingestionResponseData?.status?.missing_col ??
                                            ingestionResponseData?.status?.missingCol ??
                                            [],
                                        file_url: fileInfo?.s3?.key,
                                    };
                                } else {
                                    fileParseErrors = {
                                        is_file_empty:
                                            ingestionResponseData?.status?.isFileEmpty ?? false,
                                        accepted_rows:
                                            ingestionResponseData?.status?.acceptedRowCount ?? 0,
                                        rejected_rows:
                                            ingestionResponseData?.status?.rejectedRowCount ?? 0,
                                        total_rows:
                                            !isNaN(parseInt(accepted_rows)) &&
                                            !isNaN(parseInt(rejected_rows))
                                                ? parseInt(accepted_rows) + parseInt(rejected_rows)
                                                : 0,
                                        missing_col:
                                            ingestionResponseData?.status?.missingCol ?? [],
                                        file_url: ingestionResponseData?.status?.s3?.key ?? null,
                                        upload_error: true,
                                    };
                                }
                            }
                        } else {
                            await customTimeout(500, () => {
                                if (
                                    stage === UPLOAD_STAGE.ONBOARDING &&
                                    category === CATEGORIES.BANK_STATEMENTS
                                ) {
                                    setSelectedFiles((item) => {
                                        return item.map((file, i) => ({
                                            ...file,
                                            ...(i === index && {
                                                uploadProgress: 99,
                                            }),
                                        }));
                                    });
                                } else {
                                    setSelectedFiles((files) =>
                                        files.map((file, i) => ({
                                            ...file,
                                            ...(i === 0 && {
                                                uploadProgress: 99,
                                            }),
                                        })),
                                    );
                                }
                            });
                        }
                        await handleMetadataUpdate(index, input, data_to_post, fileParseErrors);
                    } else {
                        throw new Error(
                            "Didn't get docId in response of save meta data of doc service API",
                        );
                    }
                } else throw new Error(uploadResponse);
            } else throw new Error("Couldn't receive signed url!");
        } catch (error) {
            // updateSelectedFiles(id, true);
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Failed to upload, something went wrong',
            );
        }
    };

    const uploadDocument = async (index: number, id: number, file: File, input: any) => {
        try {
            const signResponse = await getSignedUrl(input);

            await customTimeout(500, () => {
                if (stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS) {
                    setSelectedFiles((prev) => {
                        return prev.map((item, i) => ({
                            ...item,
                            ...(i === index && {
                                uploadProgress: 40,
                            }),
                        }));
                    });
                } else {
                    setSelectedFiles((files) =>
                        files.map((file, i) => ({
                            ...file,
                            ...(i === 0 && {
                                uploadProgress: 40,
                            }),
                        })),
                    );
                }
            });

            const uploadDataPayload = {
                bucket: signResponse.data?.fields.bucket,
                ...signResponse.data?.fields,
                'Content-Type': file.type,
                file,
            };
            if (signResponse.data?.url) {
                const uploadResponse = await uploadFile(signResponse.data.url, uploadDataPayload);
                if (!uploadResponse?.ok) throw new Error(uploadResponse as any);
            } else throw new Error("Couldn't receive signed url!");

            await customTimeout(500, () => {
                if (stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS) {
                    setSelectedFiles((prev) => {
                        return prev.map((item, i) => ({
                            ...item,
                            ...(i === index && {
                                uploadProgress: 85,
                            }),
                        }));
                    });
                } else {
                    setSelectedFiles((files) =>
                        files.map((file, i) => ({
                            ...file,
                            ...(i === 0 && {
                                uploadProgress: 85,
                            }),
                        })),
                    );
                }
            });

            const data_to_post: any = {};
            data_to_post[keyName] = {};
            data_to_post[keyName].doc_link = signResponse?.file_url;
            data_to_post[keyName].doc_name = input.fileName;
            // if (addnId) {
            //     data_to_post[keyName]._id = addnId;
            // }
            data_to_post[keyName].source = '';
            if (keyName === CATEGORIES.ADDITIONAL_DATA) {
                data_to_post[keyName].description =
                    description === 'Others' ? otherValue : description;
                setDescription('');
                setOtherValue('');
                if (provideOptionToAddData) setProvideOptionToAddData(false);
            }

            if (keyName === CATEGORIES.BANK_STATEMENTS) {
                data_to_post.account_number = accountNumber;
                if (bankDetails && index === 0) {
                    data_to_post.bank_account = {};
                    data_to_post.bank_account[BANK_NAME] = bankDetails[BANK_NAME];
                    data_to_post.bank_account[ACCOUNT_HOLDER_NAME] =
                        bankDetails[ACCOUNT_HOLDER_NAME];
                    data_to_post.bank_account[ACCOUNT_NUMBER] = bankDetails[ACCOUNT_NUMBER];
                    data_to_post.bank_account[IFSC] = bankDetails[IFSC];
                    data_to_post.bank_account.account_nick_name =
                        bankDetails.bank_name + ' ' + accountNumber?.slice(-4);
                }
            }

            let fileParseErrors: any = null;

            const isPdf = getFileExtension(file.name) === 'pdf';
            if (
                (category === CATEGORIES.INVOICING ||
                    category === CATEGORIES.MIS ||
                    category === CATEGORIES.DEBT_SCHEDULE) &&
                !isTally &&
                !isPdf
            ) {
                const fileInfo: any = {
                    s3: {
                        bucket: uploadDataPayload?.bucket,
                        key: uploadDataPayload?.key,
                    },
                };
                if (
                    !(stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS)
                ) {
                    setIsUploading(false);
                }

                //Mocking File parsing
                await customTimeout(500, () => {
                    if (
                        stage === UPLOAD_STAGE.ONBOARDING &&
                        category === CATEGORIES.BANK_STATEMENTS
                    ) {
                        setSelectedFiles((item) => {
                            return item.map((file, i) => ({
                                ...file,
                                ...(i === index && {
                                    uploadProgress: 95,
                                }),
                            }));
                        });
                    } else {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 95,
                                }),
                            })),
                        );
                    }
                });
                if (category === CATEGORIES.DEBT_SCHEDULE) fileInfo.orgId = investeeOrganizationId;
                const ingestionTriggerResponse: any = await triggerFileIngestion(
                    fileInfo,
                    category,
                );
                if (category === CATEGORIES.DEBT_SCHEDULE) {
                    const ingestionResponseData = ingestionTriggerResponse?.data?.data;
                    const accepted_rows = ingestionResponseData?.rowsParsed ?? '0';
                    const total_rows = ingestionResponseData?.totalRows ?? '0';
                    const rejected_rows = ingestionResponseData?.rowsRejected ?? '0';
                    if (ingestionTriggerResponse?.status === 200) {
                        let parsedAcceptedRows = !isNaN(parseInt(accepted_rows))
                            ? parseInt(accepted_rows)
                            : null;
                        let parsedTotalRows = !isNaN(parseInt(total_rows))
                            ? parseInt(total_rows)
                            : null;
                        let parsedRejectedRows = !isNaN(parseInt(rejected_rows))
                            ? parseInt(rejected_rows)
                            : null;
                        fileParseErrors = {
                            is_file_empty:
                                ingestionResponseData?.isFileEmpty ??
                                ingestionResponseData?.isFileEmpty ??
                                false,
                            accepted_rows: parsedAcceptedRows,
                            rejected_rows: parsedRejectedRows,
                            total_rows: parsedTotalRows,
                            errorFileUrl: ingestionResponseData?.errorFileUrl,
                            missing_col:
                                ingestionResponseData?.message ??
                                ingestionResponseData?.message ??
                                [],
                            file_url: ingestionResponseData?.s3?.key,
                            isParsed: ingestionResponseData?.statusCode === 20,
                        };
                        setAcceptedRows(parsedAcceptedRows ?? 0);
                        setTotalRows(parsedTotalRows ?? 0);
                    }
                } else {
                    const ingestionResponseData = ingestionTriggerResponse?.data?.data;
                    const accepted_rows =
                        ingestionResponseData?.status?.accepted_rows ??
                        ingestionResponseData?.status?.acceptedRowCount ??
                        '0';
                    const rejected_rows =
                        ingestionResponseData?.status?.rejected_rows ??
                        ingestionResponseData?.status?.rejectedRowCount ??
                        '0';
                    if (ingestionTriggerResponse?.status === 200) {
                        fileParseErrors = {
                            is_file_empty:
                                ingestionResponseData?.status?.is_file_empty ??
                                ingestionResponseData?.status?.isFileEmpty ??
                                false,
                            accepted_rows: !isNaN(parseInt(accepted_rows))
                                ? parseInt(accepted_rows)
                                : null,
                            rejected_rows: !isNaN(parseInt(rejected_rows))
                                ? parseInt(rejected_rows)
                                : null,
                            total_rows:
                                !isNaN(parseInt(accepted_rows)) && !isNaN(parseInt(rejected_rows))
                                    ? parseInt(accepted_rows) + parseInt(rejected_rows)
                                    : 0,
                            missing_col:
                                ingestionResponseData?.status?.missing_col ??
                                ingestionResponseData?.status?.missingCol ??
                                [],
                            file_url: fileInfo?.s3?.key,
                        };
                    } else {
                        fileParseErrors = {
                            is_file_empty: ingestionResponseData?.status?.isFileEmpty ?? false,
                            accepted_rows: ingestionResponseData?.status?.acceptedRowCount ?? 0,
                            rejected_rows: ingestionResponseData?.status?.rejectedRowCount ?? 0,
                            total_rows:
                                !isNaN(parseInt(accepted_rows)) && !isNaN(parseInt(rejected_rows))
                                    ? parseInt(accepted_rows) + parseInt(rejected_rows)
                                    : 0,
                            missing_col: ingestionResponseData?.status?.missingCol ?? [],
                            file_url: ingestionResponseData?.status?.s3?.key ?? null,
                            upload_error: true,
                        };
                    }
                }
            } else {
                await customTimeout(500, () => {
                    if (
                        stage === UPLOAD_STAGE.ONBOARDING &&
                        category === CATEGORIES.BANK_STATEMENTS
                    ) {
                        setSelectedFiles((item) => {
                            return item.map((file, i) => ({
                                ...file,
                                ...(i === index && {
                                    uploadProgress: 99,
                                }),
                            }));
                        });
                    } else {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 99,
                                }),
                            })),
                        );
                    }
                });
            }
            await handleMetadataUpdate(index, input, data_to_post, fileParseErrors);
        } catch (error) {
            // updateSelectedFiles(id, true);
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Failed to upload, something went wrong',
            );
        }
    };

    const handleMetadataUpdate = async (
        index: number,
        input: any,
        data_to_post: any,
        fileParseErrors: any,
    ) => {
        const isOnboarding = path === UPLOAD_STAGE.ONBOARDING;

        try {
            if (!(stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS)) {
                setSelectedFiles((files) =>
                    files.map((file, i) => ({
                        ...file,
                        ...(i === 0 && {
                            uploadProgress: 100,
                            isUploading: false,
                            // errors: fileParseErrors,
                        }),
                    })),
                );
            }
            if (
                fileParseErrors &&
                (fileParseErrors?.is_file_empty ||
                    fileParseErrors?.accepted_rows === 0 ||
                    fileParseErrors?.upload_error) &&
                stage === UPLOAD_STAGE.DATA_VAULT
            ) {
                let fileWithErrors: any = {
                    doc_name: input?.fileName,
                    doc_link: data_to_post?.[keyName]?.doc_link,
                    errors: fileParseErrors,
                };
                if (onSuccess) onSuccess(fileWithErrors, '');
            } else if (category === CATEGORIES.DEBT_SCHEDULE) {
                let fileWithErrors: any = {
                    doc_name: input?.fileName,
                    doc_link: data_to_post?.[keyName]?.doc_link,
                    errors: fileParseErrors,
                };
                onSuccess && onSuccess(fileWithErrors);
            } else {
                const updateMetadataResponse = await updateMetadata(
                    input,
                    data_to_post,
                    isOnboarding,
                );
                if (updateMetadataResponse?.status === 'Success') {
                    let data = updateMetadataResponse?.data?.investeeOnboardingData;
                    if (fileParseErrors) {
                        let updatedFiles: any = [];
                        if (isOnboarding) {
                            updatedFiles =
                                category === INVOICING
                                    ? data?.invoicing?.file_arr
                                    : data?.mis?.file_arr ?? [];
                        } else {
                            updatedFiles =
                                category === INVOICING
                                    ? data?.onboarding_stage_data_invoicing
                                    : data?.onboarding_stage_data_financials_mis ?? [];
                        }

                        let fileWithErrors = {
                            ...updatedFiles[updatedFiles?.length - 1],
                            errors: fileParseErrors,
                        };
                        if (
                            input?.extension === 'pdf' &&
                            input?.sub_stage === 'mis' &&
                            (stage === UPLOAD_STAGE.ADD_ON || stage === UPLOAD_STAGE.ONBOARDING)
                        ) {
                            onMetadataSuccess && onMetadataSuccess(fileWithErrors, '');
                        } else {
                            if (
                                stage === UPLOAD_STAGE.ONBOARDING &&
                                category === CATEGORIES.BANK_STATEMENTS
                            ) {
                                if (index === selectedFiles.length - 1) {
                                    onSuccess && onSuccess(fileWithErrors, '');
                                }
                            } else {
                                onSuccess && onSuccess(fileWithErrors, '');
                            }
                        }
                    } else {
                        if (
                            input?.extension === 'pdf' &&
                            input?.sub_stage === 'mis' &&
                            (stage === UPLOAD_STAGE.ADD_ON || stage === UPLOAD_STAGE.ONBOARDING)
                        ) {
                            onMetadataSuccess && onMetadataSuccess(data, '');
                        } else {
                            if (
                                stage === UPLOAD_STAGE.ONBOARDING &&
                                category === CATEGORIES.BANK_STATEMENTS
                            ) {
                                if (index === selectedFiles.length - 1) {
                                    onSuccess && onSuccess(data, '');
                                } else {
                                    setBankFileErrors(
                                        data?.bank_account?.[accountNumber]?.bankUploadErrors ?? {},
                                    );
                                }
                            } else {
                                onSuccess && onSuccess(data, '');
                            }
                        }
                    }
                    if (
                        stage === UPLOAD_STAGE.ONBOARDING &&
                        category === CATEGORIES.BANK_STATEMENTS
                    ) {
                        setSelectedFiles((prev) => {
                            return prev.map((item, i) => ({
                                ...item,
                                ...(i === index && {
                                    uploadProgress: 100,
                                    isUploading: false,
                                }),
                            }));
                        });
                    }
                    if (
                        !(
                            stage === UPLOAD_STAGE.ONBOARDING &&
                            category === CATEGORIES.BANK_STATEMENTS
                        )
                    ) {
                        setIsUploading(false);
                    }
                }
            }
        } catch (err) {
            captureMessagesViaErrorLogger(err);
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Failed to upload, something went wrong',
            );
            setBankFileErrors({});
        }
        if (stage === UPLOAD_STAGE.ONBOARDING && category === CATEGORIES.BANK_STATEMENTS) {
            if (index === selectedFiles.length - 1) {
                setSelectedFiles([]);
                setIsUploading(false);
                setBankFileErrors({});
            }
        } else {
            setSelectedFiles((files) => {
                if (files?.length === 1) return [];
                else return files.filter((file, i) => 0 !== i);
            });
        }
    };

    const deletingFile = async (docLink: string, docId: string) => {
        setIsDeletingFile(docLink);
        let data_to_post: any = {};
        data_to_post.keyname = deleteKeyName ?? keyName;
        data_to_post.doc_link = docLink;
        if (keyName === CATEGORIES.BANK_STATEMENTS) {
            data_to_post.doc_link = [docLink];
            data_to_post.account_number = accountNumber;
        }
        const deleteResponse = await deleteFile(
            {
                investeeOnboardingId,
                path,
            },
            data_to_post,
            path === UPLOAD_STAGE.ONBOARDING,
        );

        if (deleteResponse?.status === 'Success') {
            const data = deleteResponse?.data?.investeeOnboardingData;
            if (onDelete) onDelete(data);
            else if (onSuccess) onSuccess(data, docId);
        } else {
            const message = deleteResponse.data?.message
                ? deleteResponse.data?.message
                : 'Some error occurred!';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
        setIsDeletingFile(null);
        setDisableDelButton(false);
    };

    /**
     * after deleting from mnemosyne we want to delete from investeOnboardingData thru olympus
     * @param docId
     */
    const deleteFromODFD = async (docId: any) => {
        const input_deleteDoc = {
            accountNumber: accountNumber,
            investeeOnboardedId: investeeOnboardingId,
            keyName: keyName,
            docLink: docId,
            deleteDoc: true,
        };
        const response = await updateODFDData(input_deleteDoc, path);
        if (response) {
            setIsDeletingFile(null);
            let data_input;
            if (path === ONBOARDING) {
                data_input = {
                    keyName: keyName,
                    value: response?.investeeOnboardingData?.bankAccount[accountNumber][keyName],
                };
            } else {
                let odOrFd;
                if (keyName.includes('od')) {
                    odOrFd = 'od';
                } else if (keyName.includes('fd')) {
                    odOrFd = 'fd';
                }
                data_input = {
                    keyName: keyName,
                    value: response?.investeeOnboardingData[`${odOrFd}`][keyName],
                };
            }
            onSuccess && onSuccess(data_input, needInfoCategory);
            setDisableDelButton(false);
        } else {
            setIsDeletingFile(null);
            const message = response.data?.message ? response.data?.message : 'Error Deleting File';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    };
    //delete from mnemosyne
    const deleteFileFromMnemosyne = async (docLink: string) => {
        setIsDeletingFile(docLink);
        await deleteFileFromDocService({
            docId: Number(docLink),
            onSuccess: () => {
                if (onDelete) {
                    const res = onDelete(docLink);
                    setIsDeletingFile(null);
                    if (res) {
                        setDisableDelButton(false);
                    }
                } else {
                    deleteFromODFD(docLink);
                }
            },
        });
    };

    useEffect(() => {
        if (category === CATEGORIES.CUSTOMER_SUPPORT_OTHER) {
            if (isUploading) setDisableDelButton(true);
            else if (!isUploading) setDisableDelButton(false);
        }
    }, [isUploading]);

    const deleteTdsEntry = async (docLink: string, docName: string) => {
        setIsDeletingFile(docLink);
        let data: any = {
            documents: [
                {
                    documentName: docName,
                    docId: '',
                },
            ],
        };
        let res;
        switch (category) {
            case CATEGORIES.TDS:
                data.customerSupportQueryId = customerSupportQueryId;
                res = await updateClaim(data);
                break;
            case CATEGORIES.CUSTOMER_SUPPORT:
                data.customerQueryId = customerSupportQueryId;
                res = await updateBalanceConfirmation(data);
        }
        if (res) {
            setIsDeletingFile(null);
            setDisableDelButton(false);
            await getTdsClaim(docName === 'FORM16A');
        } else {
            setIsDeletingFile(null);
            setDisableDelButton(false);
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Error Deleting Form16A');
        }
    };
    //splitting the delete flow for mnemosyne and core
    const handleDeleteFile = (docLink: string, docId: string, docName: string) => {
        if (!disableDelButton) {
            if (inputFileRef.current) inputFileRef.current.value = '';
            setDisableDelButton(true);
            if (!isMnemosyneFlow) {
                deletingFile(docLink, docId);
            } else {
                if (category === CATEGORIES.TDS || category === CATEGORIES.CUSTOMER_SUPPORT) {
                    deleteTdsEntry(docLink, docName);
                } else {
                    deleteFileFromMnemosyne(docLink);
                }
            }
        }
    };

    //download functionlity provided by mnemosyne
    const downloadFileFromMnemosyne = async (url: string, fileName: string) => {
        const response = await getPresignedUrl({ docId: Number(url) });
        if (response) {
            if (useMnemosyneUploadFlow) {
                const downloadFileResp = await fetch(response.uri);
                const blob = await downloadFileResp.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            } else {
                const url = response.uri;
                // download the file in new tab
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.click();
            }

            setIsDownloading(null);
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Not Able to fetch the file to show');
        }
    };

    //splitting the mnemosyne and core flow for downloading individual files
    const downloadFile = (url: string, fileName: string, isErrorFile = false) => {
        if (useMnemosyneUploadFlow || isMnemosyneFlow) {
            downloadFileFromMnemosyne(url, fileName);
            return;
        }
        const commonStringInUrl = 'amazonaws.com/';
        const indexOfCommonString = url.indexOf(commonStringInUrl);
        const urlToSend = isErrorFile
            ? url
            : url.slice(indexOfCommonString + commonStringInUrl.length);
        let input: {
            url?: string;
        } = {};
        input.url = urlToSend;
        setIsDownloading(url);
        callApi(`download-file`, 'post', input)
            .then((res) => {
                if (res.status === API_STATUS.SUCCESS) {
                    let array = new Uint8Array(res.data.file.Body.data);
                    const blob = new Blob([array]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(null);
                } else {
                    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
                    setIsDownloading(null);
                }
            })
            .catch((err) => {
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
                setIsDownloading(null);
            });
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!hideUploadAnother) {
            if (fileTypeAllowed) {
                const fileTypeExtension = fileTypeAllowed.map(
                    (ext: string) => fileTypeExtensionMap[ext] || '',
                );
                const droppedFiles = Array.from(e.dataTransfer.files);
                // If non-PDF files
                const nonPdfFiles = droppedFiles.filter(
                    (file) => !fileTypeExtension.includes(file.type),
                );
                if (nonPdfFiles.length > 0) {
                    showNotification('error', 'Upload PDF files only');
                    return;
                }
            }
            if (
                (category === CATEGORIES.TDS || category === CATEGORIES.TOTAL_REPAYMENTS) &&
                !isUploading
            ) {
                onFileChangeCapture(e, true);
                e.dataTransfer.clearData();
            }
            if (!disabled && category !== CATEGORIES.TDS) {
                onFileChangeCapture(e, true);
                e.dataTransfer.clearData();
            } else {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    "Can't upload multiple files at a time",
                );
            }
        }
    };

    const handleDrag = (e: DragEvent) => {
        if (!disabled) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === 'dragenter' || e.type === 'dragover') {
                // setDragActive(true);
                e.dataTransfer.dropEffect = 'copy';
            } else if (e.type === 'dragleave') {
                // setDragActive(false);
            }
        }
    };

    /** flow for mnemosynupload
     * 1. Modify File name
     * 2. one by one generate signed URL from mnemosyne
     * 3. Upload on that generating S3 link
     * 4. Save metadata in mnemosyne
     * 5. Save metadata in investeeOnboardingDoc olympus
     */
    const handleSubmissionMnemosyne = async () => {
        const selected_files = [...selectedFiles];
        setStartTimer(true);
        setIsUploading(true);
        let index = 0;
        callingApi.current = false;

        for (const fileObj of selected_files) {
            const { file, id } = fileObj as { file: File; id: number };

            let file_name = modifyFileNameBeforeUpload(file.name, category, isTally);
            const fileNameInput = {
                category,
                bankName: bankData?.[BANK_NAME],
                bankAccountNumber: bankData?.[ACCOUNT_NUMBER],
                docName: description === OTHERS ? otherValue : description,
                odAmount: fundingCalcAmount(bankData?.od_amount),
                fdAmount: fundingCalcAmount(bankData?.fd_amount),
                fullFileName: file_name,
            };
            const input = {
                fileName: getFullFileNameForOnBoardingAndAddOn(fileNameInput),
                flowId: getFlowId(),
                categoryId: categoryId ?? getcategoryIdForMnemosyne(category),
            };

            setSelectedFiles((files) =>
                files.map((file, i) => ({
                    ...file,
                    ...(i === 0 && {
                        uploadProgress: 0,
                    }),
                })),
            );
            await uploadDocumentMnemosyne(index, id, file, input);
            index++;
        }
    };
    const uploadDocumentMnemosyne = async (index: number, id: number, file: File, input: any) => {
        try {
            const { signedUrl, docTypeId } = await getSignedUrlToUpload({
                input: {
                    categoryId: input.categoryId,
                    fileName: input.fileName,
                    fullFileName: input?.fullFileName,
                    dk1: investeeOrganizationId,
                    dk2: path,
                },
                orgId: investeeOrganizationId ?? investorOrgId,
            });

            await customTimeout(500, () => {
                setSelectedFiles((files) =>
                    files.map((file, i) => ({
                        ...file,
                        ...(i === 0 && {
                            uploadProgress: 20,
                        }),
                    })),
                );
            });

            const requestOptions = {
                method: 'put',
                body: file,
                headers: new Headers({
                    'Content-Type': file.type,
                }),
            };

            if (signedUrl) {
                const uploadResponse: any = await fetch(signedUrl, requestOptions);
                if (uploadResponse.ok) {
                    await customTimeout(500, () => {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 40,
                                }),
                            })),
                        );
                    });
                    const input_saveMetadata: any = {
                        categoryId: input?.categoryId,
                        fileName: input?.fileName,
                        fileSizeKb: file.size / 1_000,
                        flowId: input.flowId,
                    };
                    if (category === CATEGORIES.TOTAL_REPAYMENTS)
                        input_saveMetadata['fileType'] = file.type;
                    const docData = await saveDocMetaDataInDocService({
                        input: input_saveMetadata,
                        orgId: investeeOrganizationId ?? investorOrgId,
                    });
                    await customTimeout(500, () => {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 60,
                                }),
                            })),
                        );
                    });

                    const docId = docData?.id;
                    if (docId) {
                        if (category === CATEGORIES.OD || category === CATEGORIES.FD) {
                            const input_saveODFD = {
                                accountNumber: accountNumber,
                                investeeOnboardedId: investeeOnboardingId,
                                keyName: keyName,
                                docLink: docId,
                                docName: input?.fileName,
                            };
                            const responseFromODFD = await updateODFDData(input_saveODFD, path);
                            if (responseFromODFD) {
                                await customTimeout(500, () => {
                                    setSelectedFiles((files) =>
                                        files.map((file, i) => ({
                                            ...file,
                                            ...(i === 0 && {
                                                uploadProgress: 100,
                                                isUploading: false,
                                            }),
                                        })),
                                    );
                                });
                                let data_input;
                                if (path === ONBOARDING) {
                                    data_input = {
                                        keyName: keyName,
                                        value: responseFromODFD?.investeeOnboardingData
                                            ?.bankAccount[accountNumber][keyName],
                                        accountNumber: accountNumber,
                                    };
                                } else {
                                    let odOrFd;
                                    if (keyName.includes('od')) {
                                        odOrFd = 'od';
                                    } else if (keyName.includes('fd')) {
                                        odOrFd = 'fd';
                                    }
                                    data_input = {
                                        keyName: keyName,
                                        value: responseFromODFD?.investeeOnboardingData[
                                            `${odOrFd}`
                                        ][keyName],
                                    };
                                }
                                onSuccess && onSuccess(data_input, needInfoCategory);
                                mixpanelForOdFDChecked && mixpanelForOdFDChecked(keyName);
                                setIsUploading(false);
                                setSelectedFiles((files) => {
                                    if (files?.length === 1) return [];
                                    else return files.filter((file, i) => 0 !== i);
                                });
                            }
                        } else if (
                            category === CATEGORIES.TDS ||
                            category === CATEGORIES.TOTAL_REPAYMENTS
                        ) {
                            const res = await onSuccess(docId, input?.fileName);
                            await customTimeout(500, () => {
                                setSelectedFiles((files) =>
                                    files.map((file, i) => ({
                                        ...file,
                                        ...(i === 0 && {
                                            uploadProgress: 60,
                                        }),
                                    })),
                                );
                            });
                            await customTimeout(1000, () => {
                                setSelectedFiles((files) =>
                                    files.map((file, i) => ({
                                        ...file,
                                        ...(i === 0 && {
                                            uploadProgress: 80,
                                        }),
                                    })),
                                );
                            });
                        } else if (category === CATEGORIES.CUSTOMER_SUPPORT) {
                            const res = await onSuccess(docId, input?.fileName);
                            if (res) {
                                await customTimeout(500, () => {
                                    setSelectedFiles((files) =>
                                        files.map((file, i) => ({
                                            ...file,
                                            ...(i === 0 && {
                                                uploadProgress: 100,
                                                isUploading: false,
                                            }),
                                        })),
                                    );
                                });
                                setSelectedFiles((files) => {
                                    if (files?.length === 1) return [];
                                    else return files.filter((file, i) => 0 !== i);
                                });
                                setIsUploading(false);
                            } else {
                                setIsUploading(false);
                                setSelectedFiles((files) => {
                                    if (files?.length === 1) return [];
                                    else return files.filter((file, i) => 0 !== i);
                                });
                            }
                        } else {
                            onSuccess(docId, input?.fileName);
                            await customTimeout(500, () => {
                                setSelectedFiles((files) =>
                                    files.map((file, i) => ({
                                        ...file,
                                        ...(i === 0 && {
                                            uploadProgress: 100,
                                            isUploading: false,
                                        }),
                                    })),
                                );
                            });
                            setIsUploading(false);
                            setSelectedFiles((files) => {
                                if (files?.length === 1) return [];
                                else return files.filter((file, i) => 0 !== i);
                            });
                        }
                    }
                } else {
                    throw new Error(uploadResponse);
                }
            } else throw new Error("Couldn't receive signed url!");
        } catch (err) {
            await captureMessagesViaErrorLogger(err);
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Failed to upload, something went wrong',
            );
        }
    };
    const completeFileUpload = async () => {
        setStartTimer(false);
        await customTimeout(500, () => {
            setSelectedFiles((files) =>
                files.map((file, i) => ({
                    ...file,
                    ...(i === 0 && {
                        uploadProgress: 100,
                        isUploading: false,
                    }),
                })),
            );
        });
        setSelectedFiles((files) => {
            if (files?.length === 1) return [];
            else return files.filter((file, i) => 0 !== i);
        });
        setIsUploading(false);
        setDisableDelButton(false);
    };
    useEffect(() => {
        if (
            files?.length >= 1 &&
            (category === CATEGORIES.TDS || category === CATEGORIES.TOTAL_REPAYMENTS)
        )
            completeFileUpload();
    }, [files]);
    useEffect(() => {
        if (params.get('apiFailed') === 'true') {
            setSelectedFiles((files) => {
                if (files?.length === 1) return [];
                else return files.filter((file, i) => 0 !== i);
            });
            setIsUploading(false);
            params.set('apiFailed', 'false');
            setParams(params);
        }
    }, [params.get('apiFailed')]);

    if (keyName === CATEGORIES.ADDITIONAL_DATA) {
        return (
            <AdditionalDataUpload
                otherValue={otherValue}
                setOtherValue={setOtherValue}
                category={category}
                keyName={keyName}
                files={files}
                provideOptionToAddData={provideOptionToAddData}
                description={description}
                isUploading={isUploading}
                isDownloading={isDownloading}
                inputFileRef={inputFileRef}
                mainText={mainText}
                mainTextOptional={mainTextOptional}
                deleteFile={handleDeleteFile}
                downloadFile={downloadFile}
                setDescription={setDescription}
                onFileChangeCapture={onFileChangeCapture}
                setProvideOptionToAddData={setProvideOptionToAddData}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
                isDataVault={isDataVault}
            />
        );
    }

    return (
        <>
            <div
                style={hideDragDrop ? { height: 0 } : { height: '7em' }}
                className={styles.UploadDocBox}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
            >
                <input
                    type="file"
                    ref={inputFileRef}
                    onChangeCapture={onFileChangeCapture}
                    style={{ display: 'none' }}
                    onClick={(e) => (e.currentTarget.value = '')}
                    disabled={isUploading || disabled}
                    accept={getInputAcceptValue(extensionCategory, keyName)}
                    multiple={
                        category === CATEGORIES.MIS ||
                        category === CATEGORIES.BANK_STATEMENTS ||
                        category === CATEGORIES.INVOICING ||
                        category === CATEGORIES.OD ||
                        category === CATEGORIES.FD ||
                        allowMultiple
                    }
                />

                {!!!renderComponent ? (
                    <div className="text-grey15 fs-10 ff-gm" style={{ marginBottom: '5px' }}>
                        {mainText}
                    </div>
                ) : (
                    <div
                        onClick={(e) => handleClick(e)}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        style={
                            isUploading || disabled
                                ? { cursor: 'no-drop' }
                                : { cursor: 'pointer', height: '100%' }
                        }
                    >
                        {renderComponent}
                    </div>
                )}
            </div>
            <div>
                {category !== CATEGORIES.TOTAL_REPAYMENTS && (
                    <div className={styles.BankStatementWrapper}>
                        {category === CATEGORIES.BANK_STATEMENTS &&
                            (!isEmpty(bankFileErrors) || !isEmpty(fileErrors)) &&
                            Object.keys(!isEmpty(bankFileErrors) ? bankFileErrors : fileErrors).map(
                                (item) => {
                                    return (
                                        <BankStatementErrors
                                            fileErrors={
                                                !isEmpty(bankFileErrors)
                                                    ? bankFileErrors
                                                    : fileErrors
                                            }
                                            item={item}
                                        />
                                    );
                                },
                            )}
                    </div>
                )}
                {files?.map((file: any, index: number) => {
                    if (
                        (category === CATEGORIES.TDS || category === CATEGORIES.TOTAL_REPAYMENTS) &&
                        !file.source &&
                        !file?.is_deleted
                    ) {
                        return (
                            <SingleFile
                                hideDeleteIcon={hideDeleteIcon}
                                key={index}
                                file={file}
                                downloadFile={downloadFile}
                                deleteFile={handleDeleteFile}
                                isDownloading={isDownloading}
                                isDeletingFile={isDeletingFile}
                                category={category}
                                isTally={isTally}
                                truncateSmallerText={truncateSmallerText}
                            />
                        );
                    } else if (
                        !file.source &&
                        !file?.is_deleted &&
                        (category !== CATEGORIES.TDS || category !== CATEGORIES.TOTAL_REPAYMENTS)
                    ) {
                        /*
                            @TODO
                            store totalRows and acceptedRows for every file to display teh history of how many rows got imported 
                        */
                        if (index === files.length - 1) {
                            file.totalRows = totalRows;
                            file.acceptedRows = acceptedRows;
                        }
                        return (
                            <SingleFile
                                disabled={disabled}
                                isInvestor={isInvestor}
                                hideDeleteIcon={hideDeleteIcon}
                                key={index}
                                file={file}
                                downloadFile={downloadFile}
                                deleteFile={handleDeleteFile}
                                isDownloading={isDownloading}
                                isDeletingFile={isDeletingFile}
                                customStyleForFileName={isInvestor ? { color: '#6021B3' } : {}}
                                category={category}
                                isTally={isTally}
                                truncateSmallerText={truncateSmallerText}
                                fileErrors={fileErrors}
                            />
                        );
                    }
                    return null;
                })}
                {selectedFiles?.length
                    ? selectedFiles?.map((file: any, index: number) => (
                          <div className={styles.ProcessingContainer} key={index}>
                              <div
                                  className={styles.ProcessingFile}
                                  key={`${file.id}-${file.name}`}
                              >
                                  <span
                                      style={isInvestor ? { color: '#6021B3' } : {}}
                                      className={`${styles.textTruncate} ${styles.downloadable}`}
                                      title={file.doc_name}
                                  >
                                      {file.file.name}
                                  </span>
                                  <span className={styles.smallText}>
                                      {category === CATEGORIES.TDS ||
                                      category === CATEGORIES.TOTAL_REPAYMENTS
                                          ? countDown > 0
                                              ? `Estimated ${countDown} sec`
                                              : 'Taking Longer Than Usual'
                                          : stage === UPLOAD_STAGE.ONBOARDING &&
                                            category === CATEGORIES.BANK_STATEMENTS
                                          ? file.isUploading
                                              ? 'Importing File'
                                              : null
                                          : 'Importing File'}
                                  </span>
                              </div>
                              {file?.isUploading ? (
                                  <div className={styles.ProgressContainer}>
                                      <div
                                          className={styles.Bar}
                                          style={
                                              isInvestor
                                                  ? {
                                                        width: `${file.uploadProgress}%`,
                                                        backgroundColor: '#6021B3',
                                                    }
                                                  : { width: `${file.uploadProgress}%` }
                                          }
                                      ></div>
                                  </div>
                              ) : null}
                          </div>
                      ))
                    : null}

                {!renderComponent && !isUploading && (
                    <>
                        {files?.length ? (
                            hideUploadAnother ? (
                                <></>
                            ) : (
                                <UploadAnotherFile handleClick={handleClick} />
                            )
                        ) : (
                            <UploadDocBox
                                handleClick={handleClick}
                                handleDrag={handleDrag}
                                handleDrop={handleDrop}
                                mainText={mainText}
                            />
                        )}
                    </>
                )}

                {!!showMisMergePopup && (
                    <MISMergePopup
                        showMisMergePopup={showMisMergePopup}
                        onClose={() => setShowMisMergePopup(false)}
                        setSelectedFiles={setSelectedFiles}
                        callingApi={callingApi}
                    />
                )}
            </div>
        </>
    );
}
